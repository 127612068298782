import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupplierAuthGuardService implements CanActivate {

  constructor() { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean  {
    if (JSON.parse(sessionStorage.getItem('SupplierLogin'))) {
			return true;
		}else if(JSON.parse(sessionStorage.getItem('commonIndex'))){
			sessionStorage.setItem('commonIndex', JSON.stringify(false));
			window.location.href=environment.hostUrl+'index';
		}else{
			window.location.href=environment.hostUrl;
		}
		return false;
  }
}
