<mat-toolbar color="primary" class="example-toolbar   ">

  <a routerLink="/dashboard"><img src="/assets/image/logo5.jpg" alt="Ford" class="logo" width="100px" height="45px"
      style="margin-right: 3px; margin-top: 2px;"></a>
  <span class="line"></span>
  <h1 class="projectTitle">PANDA</h1>
</mat-toolbar>
<p-card header="Terms & Condition" subheader="" [style]="{width: '360px'}" styleClass="p-card-shadow" *ngIf="agreeTerms">
  <p class="termsCondition">Ford is working to continually improve and expand the FordParts programs, features and
    functionality for our customers. These Terms describe programs and features currently available as well as
    features we expect to add in the future. The Terms apply to all of the features described below, but you can check
    the FordParts mobile app to see which ones are currently available. FordParts may also integrate with other
    services or devices, and these features may be subject to separate or supplemental terms or privacy practices –
    these will be presented to you in connection with that feature. Check back often as we add new programs and
    features to understand what FordParts can do for you!
    Before we get started, we want you to know how FordParts works and we need you to click “Accept” to accept our
    Terms. Please review carefully.
    A few important points:
  </p>
  <p class="termsConditionBullets">
    • We update FordParts from time to time to introduce new features or fix issues. In some cases, you may need to
    install the newest version of the App before you can use FordParts again.
  </p>
  <p class="termsConditionBullets">
    • These Terms contain important disclaimers and other provisions that you need to be aware of.
  </p>
  <p class="termsCondition">
    You can use the Table of Contents below to easily move through these Terms:
  </p>
  <p class="termsConditionHeader">
    1. General Information
  </p>
  <p class="termsCondition"> These Terms apply to your access to and use of the FordParts mobile application (the
    “App”) which is owned and/or administered by Ford Motor Company, One American Road[MV(1] , Dearborn, Michigan
    48126 United States of America (hereinafter “Ford”, “we”, “us”). These Terms do not alter in any way the terms of
    any other agreement you may have with us. If you want to retain a copy of these Terms, please visit this link and
    save or print them
  </p>
  <p class="termsConditionBullets">
    a. Acceptance
    By clicking "Accept" and/or using FordParts or its features, you agree to be bound by these Terms. You must be at
    least 18 years old, or the age of majority, as determined by the laws of your state or territory of residency, in
    order to accept these Terms, and hold a FordParts account. FordParts is only open to individuals and companies.
    We may modify these Terms at any time. We will alert you through FordParts of any material changes to these Terms,
    and allow you an opportunity to accept the modified Terms. Other modifications will be effective immediately upon
    being posted on FordParts or at such later time as we may specify, and your continued use of FordParts or its
    features will indicate your acceptance of the modified Terms. If you do not agree to these Terms or any
    modification of these Terms, then you must immediately stop using FordParts.
    We reserve the right to change, update, improve, correct, modify, suspend, discontinue or cancel all or part of
    FordParts, including any of its features, functionalities or manners of operation at any time without any notice
    or obligation to you. Your use of FordParts after any changes are implemented will be considered acceptance of
    such changes. FordParts may not be available during maintenance breaks and other times.
    If you object to any changes to FordParts or to these Terms, your sole recourse is to stop using FordParts.We
    reserve the right to change, update, improve, correct, modify, suspend, discontinue or cancel all or part of
    FordParts, including any of its features, functionalities or manners of operation at any time without any notice
    or obligation to you. Your use of FordParts after any changes are implemented will be considered acceptance of
    such changes. FordParts may not be available during maintenance breaks and other times.
    If you object to any changes to FordParts or to these Terms, your sole recourse is to stop using FordParts.
  </p>
  <p class="termsConditionBullets">
    b.System Requirements/Updates/Changes/Cancellation
    Use of FordParts requires a mobile telephone or handheld device, internet access (fees may apply), and software
    and may require obtaining updates or upgrades from time to time. Contact your internet service provider or data
    carrier for more information regarding usage rates and fees.
    In some cases, you may need to install the newest version of the App before you can use FordParts again.
    You further agree that Ford, its affiliates, service providers, and/or designated agents may periodically check
    the version of the Software and may remotely deliver Software updates, upgrades, supplements, or changes to the
    Software without any further notice or additional consent. Data usage rates and fees may apply.
    You are responsible for keeping your FordParts account information and password secret, and for any activity that
    occurs under your account, including unauthorized activity. You agree to notify us immediately of any unauthorized
    use of your account or password.
    To uninstall FordParts from your mobile device, you must use the application management tools provided with your
    device. Consult your device manual for assistance.
    To cancel your FordParts Account, contact Customer Support at FordParts@ford.com.
  </p>
  <p class="termsConditionHeader">
    2. Privacy Policy
  </p>
  <p class="termsCondition">
    The FordParts Privacy Policy will help you understand what personal information we collect from FordParts mobile
    app, why we collect it, and what we do with it, in order to better serve you. Please review those privacy policies
    to understand how they collect and use data.
    Information We Collect: We collect the following types of information through your use of FordParts
  </p>
  <p class="termsConditionBullets">
    • Mobile Device Information, such as software or operating system version, unique device identifiers, IP address,
    mobile network information, and mobile phone number and motion related information from your mobile device’s
    accelerometer, gyroscope and compass.
  </p>
  <p class="termsConditionBullets">
    • Information about how you use FordParts, such as when and for how long you use features and when you contact us,
    a record of your communication.
  </p>
  <p class="termsConditionBullets">
    • FordParts may also use tracking technologies that collect information about how FordParts is used and how it is
    performing including if it crashes, and which may be able to be connected across devices and over time. These may
    include cookies (small files that are stored on your computer or device), pixels or web beacons (small pieces of
    code placed on web pages and content that communicate when the page or content has been viewed), log data (logs
    created that include IP address, date and time of activity, and the website or location that referred you to
    FordParts), or third-party analytics tools, such as Adobe Analytics, Aptelligent, and DynaTrace). Some of these
    technologies can be limited or deactivated through your browser or device settings, but some cannot. FordParts
    does not respond to “Do-Not-Track” requests.
  </p>
  <p class="termsCondition">
    How We Use Information We Collect: We use your information to provide you with great functionality and services,
    troubleshoot problems, develop new and improved products, services, and marketing strategies and research, to
    protect safety, property, privacy, and security, or to comply with legal requirements

    Security and Retention of Your Information: We use systems, policies, procedures, and technology to provide
    reasonable security to protect and maintain the security and accuracy of your information. We will only retain
    your personal information for so long as reasonably necessary to fulfill legitimate business purposes.
    Privacy Policy Effective Date and Revisions: This Privacy Policy may be updated in order to reflect any changes to
    FordParts or our privacy practices. See the General Information section for effective date and more information.

  </p>
  <p class="termsConditionHeader">
    3. Safety
  </p>
  <p class="termsCondition">
    Warning: We recommend against the use of any hand-held device while driving, riding bicycles, or any other
    activity that requires your full attention, and encourage the use of voice-operated systems when possible.
    Accessing or using FordParts on hand-held devices while driving, riding a bicycle, or other activity could
    distract your attention and result in loss of vehicle control, crash and injury. FordParts may send messages or
    push notifications to your cell phone. Do not read messages on hand-held devices while driving, riding a bicycle,
    or similar activities.
  </p>
  <p class="termsConditionHeader">
    4. Limited Licenses
  </p>
  <p class="termsCondition">
    FordParts and any content (images, logos, text, music, sounds, wallpaper, badges, etc.), data or software made
    available through or in connection with FordParts is owned by Ford Motor Company or used under license. FordParts
    Content is protected by worldwide copyright, trademark, patent, trade secret, or other proprietary rights whether
    or not a copyright notice or other proprietary mark is present. You agree to comply with all copyright laws in
    your use of FordParts Content, including preventing any unauthorized copying. Except as expressly provided herein,
    we do not grant any express or implied proprietary rights to FordParts Content.
  </p>
  <p class="termsCondition">
    Subject to your compliance with these Terms and any other terms communicated in connection with specific FordParts
    Content, we grant you a non-exclusive, non-transferable, limited right to access, view, use, display and listen to
    FordParts Content for your personal, non-commercial use only. You agree not to dispute our claims of ownership or
    validity of our rights in FordParts Content.
    If you violate any of the Terms, your rights will immediately terminate and we may terminate your access to
    FordParts Content without notice and without any refund of fees, if applicable.
    Any right or authorization granted to you by us is also subject to the following restrictions:

  </p>
  <p class="termsConditionBullets">
    • you may not, nor allow third parties to create derivative works, use any data mining, robots, or similar data
    gathering and extraction tools, create a database, download or store FordParts Content other than as licensed
    above, link or frame FordParts Content, extract, derive or attempt to extract or derive any source code or
    structure of all or any portion of FordParts Content by reverse engineering, disassembly, decompilation or any
    other means;
  </p>
  <p class="termsConditionBullets">
    • you may not use FordParts Content in any manner that is unlawful, abusive, defamatory, deceptive, or invasive of
    another’s privacy;
  </p>
  <p class="termsConditionBullets">
    • you may not use FordParts Content with other content or in a manner that impersonates any person, business or
    entity, including us;
  </p>
  <p class="termsConditionBullets">
    • you may not interfere, try to interfere, disrupt, or try to disrupt our servers or networks, or disobey any of our
    network access or security requirements;
  </p>
  <p class="termsConditionBullets">
    • you may not use FordParts Content to engage in conduct that reflects poorly upon or disparages our reputation or
    goodwill; and
  </p>
  <p class="termsConditionBullets">
    • you may not use FordParts Content in conjunction with adult content or that promotes illegal activities, gambling,
    or the sale of tobacco or alcohol.
  </p>
  <p class="termsConditionBullets">
    • If you are not the bill payer for the mobile telephone or device being used to access FordParts Content, you will
    be assumed to have received permission from the bill payer to use FordParts Content or Features.
  </p>
  <p class="termsConditionHeader">
    5. Interference
  </p>
  <p class="termsCondition">
    CAUTION: ANY ATTEMPT BY ANY INDIVIDUAL TO DELIBERATELY DAMAGE FORDPARTS OR OTHERWISE UNDERMINE OUR LEGITIMATE
    BUSINESS OPERATIONS MAY BE IN VIOLATION OF CRIMINAL AND CIVIL LAWS AND WE RESERVE THE RIGHT TO COOPERATE IN THE
    PROSECUTION OF ANY SUCH INDIVIDUAL(S) AND TO PURSUE ALL REMEDIES TO THE FULLEST EXTENT PERMITTED BY LAW.
  </p>
  <p class="termsConditionHeader">
    6. Trademark Notice
  </p>
  <p class="termsCondition">
    The Ford name, and all trademarks and logos displayed on FordParts are owned or used under license by us. The
    unauthorized use of any trademark displayed on FordParts or included in any FordParts Content is prohibited.
  </p>
  <p class="termsConditionHeader">
    7. User Generated Content
  </p>
  <p class="termsCondition">
    To the extent you submit information or content to us (“User Submissions”), you understand and agree that they will
    not be treated in confidence by us except for personal information pursuant to the FordParts Privacy Policy. You are
    solely responsible for your User Submissions and any consequences of posting or publishing them. You understand that
    many people from around the world will be able to see your postings and comments. You also agree that we and our
    third party service providers may contact you via email about any submission.
    New or confidential ideas are prohibited on FordParts. If you have an innovative idea, please use the official Ford
    process at: http://corporate.ford.com/innovation/innovation-ideas-submission.html.

  </p>
  <p class="termsConditionHeader">
    8. Indemnity and Hold Harmless
  </p>
  <p class="termsCondition">
    By using FordParts you agree that you will defend, indemnify and hold harmless us and our subsidiaries and
    affiliates, their respective distributors, dealers, dealer associations, suppliers, licensors, partners and
    advertising and promotions agencies, from and against all the liabilities, claims, damages and expenses (including
    reasonable attorneys’ fees and costs) arising out of any use of FordParts by or through you including any of its
    features or services, your breach or alleged breach of these Terms, and/or your breach or alleged violation of the
    patent, copyright, trademark, proprietary or other rights of third parties. We reserve the right to assume the
    exclusive defense and control of any matter otherwise subject to indemnification by you, and, in such case, you
    shall agree to cooperate with our defense of such claim
    You acknowledge and agree that your app store provider has no liability, responsibility or obligation to you,
    including with respect to the investigation, defense, settlement or discharge of a third party’s intellectual
    property infringement claim
  </p>
  <p class="termsConditionHeader">
    9. Disclaimer
  </p>
  <p class="termsCondition">
    FordParts and/or any of their features may be unavailable, inaccurate or interrupted from time to time for a variety
    of reasons outside of our control. We are not responsible for any unavailability, interruptions or errors of
    FordParts or its features. We may modify, suspend, or discontinue FordParts, or any feature at any time without
    notice. FordParts, and all the information accessible through each are provided for information purposes only on an
    “as is” and “as available” basis. We, our information providers and their agents make no warranties,
    representations, or guarantees of any kind, express or implied, including but not limited to, accuracy, currency, or
    completeness, the operation of FordParts, the information, materials, content, availability, and products. To the
    fullest extent permitted by applicable law, we disclaim all warranties, express or implied, including but not
    limited to, implied warranties of merchantability and fitness for a particular purpose
  </p>
  <p class="termsCondition">
    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, OUR OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, SHAREHOLDERS,
    REPRESENTATIVES AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH FORDPARTS AND YOUR USE
    THEREOF. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW WE, OUR INFORMATION PROVIDERS AND THEIR AGENTS ASSUME NO
    LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR
    PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF FORDPARTS, (III) ANY
    UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
    INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM FORDPARTS, (V) ANY BUGS,
    VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH FORDPARTS BY ANY THIRD-PARTY, AND/OR (VI)
    ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
    CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA FORDPARTS.
    TO THE FULLEST EXTENT PERMITTED BY LAW WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
    PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD-PARTY THROUGH FORDPARTS OR ANY HYPERLINKED WEBSITE OR APP, OR
    FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR ANY
    TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
    SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
    APPROPRIATE. NEITHER WE, OUR INFORMATION PROVIDERS OR THEIR AGENTS ARE PROVIDING ANY LEGAL, TAX, MEDICAL,
    COUNSELING, ACCOUNTING, INVESTMENT, FINANCIAL OR ANY OTHER PROFESSIONAL SERVICES OR ADVICE.
    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL WE, OR OUR OFFICERS, DIRECTORS, EMPLOYEES,
    AFFILIATES, SHAREHOLDERS, REPRESENTATIVES OR AGENTS BE LIABLE FOR ANY, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
    PUNITIVE, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO LOSS OF INCOME, PROFITS, GOODWILL, DATA, OR
    USE OF MONEY, WHETHER IN TORT (INCLUDING WITHOUT LIMITATION NEGLIGENCE), CONTRACT OR OTHERWISE, ARISING OUT OF OR
    RELATED TO ANY ACCESS TO, USE OF, OR INABILITY TO ACCESS OR USE FORDPARTS OR ANY OF ITS FEATURES, FUNCTIONALITIES,
    CAPABILITIES OR CONTENT, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

  </p>
  <p class="termsCondition">
    This section does not affect, and is not intended to affect, any rights that a consumer has that are not able to be
    excluded under applicable consumer laws.
  </p>
  <p class="termsConditionHeader">
    10. Miscellaneous
  </p>
  <p class="termsCondition">
    These Terms together with any additional terms to which you agree when using particular elements of FordParts
    constitute the entire agreement regarding FordParts, and any services. Unless and to the extent local law requires
    otherwise, these Terms are governed by, and construed in accordance with, the laws of the State of Michigan, except
    in relation to any conflict of law provisions thereof. Unless local law mandates otherwise, all lawsuits arising
    from or relating to these Terms shall be brought in the Federal or State courts located in Wayne County, Michigan.
    If a court determines that any term or condition in these Terms is illegal or unenforceable, then such term will be
    eliminated and the remaining terms and conditions will remain in full force and effect. Our failure to exercise or
    enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. If any
    provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree
    that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the
    other provisions of these Terms remain in full force and effect.
  </p>
  <p class="termsConditionHeader">
    11. Contact Us
  </p>
  <p class="termsCondition">
    If you have any questions, comments, or claims regarding FordParts, you may contact us via Email FordParts@ford.com.
  </p>
  <div class="p-d-flex">
    <div class="p-mr-2"> <button pButton id="btnSearch" type="button" label="Back" icon="pi pi-chevron-left"
        class="btn-Search  mb-2 mr-2 " (click)="back()"></button>
    </div>
  </div>
</p-card>
<p-card *ngIf="!agreeTerms" header=""  [style]="{width: '360px'}" styleClass="p-card-shadow">
<div class="p-field-checkbox">
  <p-checkbox binary="true" [(ngModel)]="enableDownload" inputId="binary"></p-checkbox>
  <label for="binary" class="labelText" (click)="agreeCondition()"><u>I agree to the Terms & Condition</u></label>
</div>
  <p-card><p class="termsConditionHeader"><a routerLink="/dashboard"><img src="/assets/image/androidLogo.png" alt="Ford" class="logo"
  style="margin-right: 20px; margin-top: 2px;"></a>PANDA Android</p>   <div class="p-d-flex justify-content-center">
    <div class="p-mr-2"> <button pButton id="btnSearch" type="button" label="Download" icon="pi pi-download"
        class="btn-Search  mb-2 mr-2 " [disabled]="!enableDownload" (click)="downloadApk()">
      </button>
    </div>
  </div></p-card>
<br>
<p-card><p class="termsConditionHeader"><a routerLink="/dashboard"><img src="/assets/image/iosLogo.png" alt="Ford" class="logo"
  style="margin-right: 20px; margin-top: 2px;"></a>PANDA iOS </p>  <div class="p-d-flex justify-content-center">
    <div class="p-mr-2"> <button pButton id="btnSearch" type="button" label="Download" icon="pi pi-download"
        class="btn-Search  mb-2 mr-2 " [disabled]="!enableDownload" (click)="downloadipa()"></button>
    </div>
  </div></p-card>
</p-card>
