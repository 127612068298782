<div class="fullscreen ">
    <header id="app-header" class="container-fluid">
        <div class="row m-0">
            <div class="col-lg-7 col-md-7 col-sm-12 col-12">
                <div class="d-flex mb-0">
                    <div class="p-2">
                        <div id="app-icon" style="margin-left: 10px;">
                            <img id="ford-icon" alt="Ford Oval" src="assets/image/common_landing_logo.png" />
                            <div id="app-header-text">
                                <h1 id="panda-header">PANDA</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12 text-right">
                <div class="d-flex flex-column bd-highlight mt-3 mr-2">
                    <div class="h6 pr-2 mb-1">
                        Hello
                    </div>
                    <div class="h6 pr-2 font-weight-bold">
                        {{userData?.name}}
                    </div>
                </div>
            </div>
        </div>
        <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #00095b; height: 40px;">
                <div class="p-d-flex ml-auto" style="color: #e3e6ea" (click)="logout()">
                    <div class="p-mr-2 mt-2" style="cursor: pointer">
                        <mat-icon>logout</mat-icon>
                    </div>
                </div>
        </nav>
    </header>
</div>
<div class="fullscreen-image">
    <div class="image"></div>
    <div class="container" id="options-section">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                <div class="card centered" id="option-card" (click)="navigateToDealer()">
                    <img src="../../assets/image/dealer.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <div class="h5 text-center panda-options">Dealer</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                <div class="card centered" id="option-card" (click)="navigateToSupplier()">
                    <img src="../../assets/image/dealer2.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <div class="h5 text-center panda-options">Supplier</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>