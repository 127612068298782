import { Component, OnInit, VERSION, NgZone,ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { environment } from 'src/environments/environment';
import { CommonService } from '../dealer/services/common.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import {MatSidenav} from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

@Component({
	selector: 'dealer',
	templateUrl: './dealer.component.html',
	styleUrls: ['./dealer.component.scss'],
})
export class DealerComponent implements OnInit {
	@ViewChild('snavweb') sidenav: MatSidenav;
	public hostUrl = environment.hostUrl;
	mobileQuery: MediaQueryList;
	/** string used to hold the url for the skipToMain link */
	skipToMain: string;
	dealerId: string;
	userId: string;
	isProfileClicked = false;
	dealerCode: any[];
	errorMsg: boolean = false;
	selectedCategory: any;
	displayMaximizable: boolean;
	isActiveDirectory: boolean;
	
	/** the Angular version */
	/** constructor for setting up DI in this component */
	private _mobileQueryListener: () => void;
	multiDealerEnable: boolean = false;
	private _mediaSubscription: Subscription;
      sidenavOpen = true;
      isMobile = false;
	  close() {
		
		this.sidenav.close();
	  }
	
	constructor(
		private readonly router: Router,
		changeDetectorRef: ChangeDetectorRef,
		private dealerService: CommonService,
		private zone: NgZone,
		private iab: InAppBrowser,
		media: MediaMatcher,
		private navigation: NavigationService,
		
	)
	{
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
	}
	

	/** this class requires this method to implement the OnInit interface */
	ngOnInit() {

		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.setSkipLinkUrl(event.urlAfterRedirects);
			});
		const tokenInfo = this.getTokenInfo(
			sessionStorage['gateway_access_token']
		);
		console.log(tokenInfo);
		const id = tokenInfo.sub;
		const countryCode = tokenInfo.country;
		console.log('******** Login Detail ******************');
		console.log(id);
		this.userId = id;
		sessionStorage['dealerId'] = id;
		sessionStorage['country']=countryCode;
		console.log(countryCode);
		console.log(sessionStorage['dealerToken']);
		this.isActiveDirectory = JSON.parse(
			sessionStorage.getItem('isActiveDirectory')
		);
		this.dealerId = sessionStorage['dealerCode'];

		
		if (sessionStorage['dealerToken'] == 'Test' && tokenInfo.ACIGROUP === 'Dealer') 
		{
			this.fetchDealerCode();
		} 
		else 
		{
			sessionStorage['dealerCode'] = '';
		}
		this.multiDealerEnable = sessionStorage['multiDealer'];
	
	}


	fetchDealerCode() 
	{
		this.dealerService.fetchDealerCode(sessionStorage['dealerId']).subscribe((res) =>
		 {
				this.responseHandler(res);
		 });
	}

	swapDealer()
	{
		this.fetchDealerCode();
		this.selectedCategory = sessionStorage['dealerCode'];
	}
	closeSideNav()
	{
		if(this.mobileQuery.matches)
		{
			this.sidenav.close();
		}
	}

	navigateToBackOrder() 
	{
		this.navigation.isBackActive = false;
		this.dealerService.backorderFilter="";
		this.router.navigateByUrl('/dealer/dealer-backorder');
	}
	navigateToPartSearch() 
	{
		this.navigation.isBackActive = false;
		this.dealerService.partNumberFilter="";
		this.router.navigateByUrl('/dealer/dealer-part-search');
	}
	navigateToPartInq() {
		
		this.navigation.isBackActive = false;
		this.router.navigateByUrl('/dealer/parts-enquiry');
		
	}
	navigateToSupersession() {
		
		this.navigation.isBackActive = false;
		this.dealerService.superSessionFilter="";
		this.router.navigateByUrl('/dealer/dealer-supersession');
		

	}

	getTokenInfo(token: string): any {
		return JSON.parse(atob(token.split('.')[1]));
	}

	/**
	 * setSkipLinkUrl takes in a url string and processes whether
	 * the skipToMain link should be updated to use the new value
	 * @param currentUrl the new url to refer to
	 */
	private setSkipLinkUrl(currentUrl: string) {
		if (!currentUrl.endsWith('#app-content')) {
			this.skipToMain = `${currentUrl}#app-content`;
		}
	}

	showProfile(event) {
		this.isProfileClicked = !this.isProfileClicked;
		event.stopPropagation();
	}

	hideProfile() {
		this.isProfileClicked = false;
	}

	stopPropogation(event) {
		event.stopPropagation();
	}
	logout() {
		console.log('*** Logout ***');
		localStorage.clear();
		sessionStorage.clear();
		document.location.href =
			'https://wwwqa.faust.idp.ford.com/logout?back=' + this.hostUrl;
	}



	responseHandler(response) {
		this.dealerCode = response;
		console.log('** Size **');
		console.log(response.length);
		if (response.length > 1) {
			sessionStorage['multiDealer'] = true;
			this.multiDealerEnable = true;
			this.displayMaximizable = true;
		} else {
			sessionStorage['dealerCode'] = response[0];
			this.dealerId = response[0];
			sessionStorage['dealerToken'] = '';
		}
	}

	setDealer() {
		console.log('*** Dealer Code ***');
		if (this.selectedCategory) {
			console.log(this.selectedCategory);
			sessionStorage['dealerCode'] = this.selectedCategory;
			sessionStorage['dealerToken'] = '';
			this.dealerId = this.selectedCategory;
			this.displayMaximizable = false;
		} else {
			console.log('*** Error Msg ***');
			this.errorMsg = true;
		}
	}
}
