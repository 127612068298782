<div class="fullscreen m-0">
    <header id="app-header" class="container-fluid m-0">
        <div class="row m-0">
            <div class="col-lg-7 col-md-7 col-sm-12">
                <div class="d-flex mb-0">
                    <div class="p-2 bd-highlight">
                        <button type="button" id="sidebarCollapse" class="btn btn-info m-2" (click)="onToggle()">
                            <i class="fas fa-align-left"></i>
                        </button>
                    </div>
                    <div class="p-2">
                        <div id="app-icon" style="margin-left: 10px;">
                            <img id="ford-icon" alt="Ford Oval" src="assets/image/FordOval.png" />
                            <div id="app-header-text">
                                <h1 id="panda-header">PANDA</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-10 col-8 user-data">
                <div class="d-flex flex-column bd-highlight">
                    <div style="color: #00095b;" class="pr-2">
                        Delivery Location
                    </div>
                    <div class="h6 pr-2" style="color: #00095b;">
                        {{market}}-{{gsdbCode}}
                        <i data-toggle="modal" id="market-gdsb" data-target="#staticBackdrop"
                            class="fas fa-chevron-down ml-1" style="color: #00095b;"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-4 user-data">
                <div class="d-flex flex-column bd-highlight">
                    <div class="h6 pr-2 mb-1" style="color: #00095b;">
                        Hello
                    </div>
                    <div style="color: #00095b;" class="h6 pr-2 font-weight-bold">
                        {{userData?.name}}
                    </div>
                </div>
            </div>
        </div>
        <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #00095b;">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="navbarNavAltMarkup">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" routerLink="/supplier/home" (click)="setActiveMenuItem(0)">Home</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link">Supplier</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link">Help</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" (click)="logout()">Logout</a>
                    </li>
                    <li class="nav-item active" *ngIf="isActiveDirectory">
                        <a class="nav-link" (click)="FDSHome()">FDS Home</a>
                    </li>
                </ul>
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link" style="color: white;">{{ today | date:'dd-MMM-yyyy' }}</a>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
    <div class="wrapper">
        <nav id="sidebar" [class.active]="sideBar">
            <ul class="list-unstyled components" id="main-menu-item">
                <li *ngFor="let item of menuContents" [class.active]="selectedMenu === item?.index">
                    <a class="menu-item" (click)="setActiveMenuItem(item?.index)" routerLink="{{item?.routerLink}}" data-toggle="collapse"
                        aria-expanded="false">{{item?.menuLabel}}</a>
                </li>
            </ul>
        </nav>

        <div class="container-fluid mt-2">
            <router-outlet></router-outlet>
        </div>
    </div>
    <footer id="footer">
        <div class="col-12 text-center">
            <p id="copyright-footer">
                <span class="far fa-copyright copyright-icon"></span>&nbsp;2021 Ford Motor Company
            </p>
        </div>
    </footer>
</div>

<div class="modal fade" id="staticBackdrop" data-keyboard="false" data-backdrop="static" tabindex="-1" disableClose="false"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
	<!-- Required for message service -->
	<p-toast></p-toast>
	<p-toast position="bottom-center" key="c" (onClose)="onReject()" [baseZIndex]="5000">
		<ng-template let-message pTemplate="message">
			<div class="p-flex p-flex-column" style="flex: 1">
				<div class="p-text-center">
					<i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
					<h4>{{message.summary}}</h4>
					<p>{{message.detail}}</p>
				</div>
				<div class="p-grid p-fluid">
					<div class="p-col-6">
						<button type="button" pButton (click)="onConfirm()" label="Yes" class="p-button-success"></button>
					</div>
					<div class="p-col-6">
						<button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
					</div>
				</div>
			</div>
		</ng-template>
	</p-toast>
	<div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container" id="market-datum">
                    <div class="row justify-content-md-center">
                        <div class="col col-lg-2">

                        </div>
                        <div class="col-md-auto">
                            <div class="h6 market-class">Hello</div>
                            <div class="h5 market-class">{{userData?.name}}</div>
                            <div class="h6 mt-3 mb-1 market-class">Select Delivery Location and Supplier Code</div>
                            <div class="form-group mt-3 market-class">
                                <label for="market">Delivery Location (<span style="color: red;">*</span>)</label> <br>
                                <Select class="form-control" name="marketValue"
                                    (change)="changeMarket(marketValue.value)" ngDefaultControl ngModel
                                    #marketValue="ngModel">
                                    <option hidden value="" disabled selected>Select Delivery Location </option>
                                    <option *ngFor="let market of markets" [value]="market?.marketName">
                                        {{market.marketName}}</option>
                                </Select>
                            </div>
                            <div class="form-group market-class">
                                <label for="market">Supplier Code (<span style="color: red;">*</span>)</label><br>
                                <Select class="form-control" id="gsdb" name="gsdb" (change)="changeGSDB(gsdb.value)"
                                    ngDefaultControl ngModel #gsdb="ngModel">
                                    <option hidden value="" disabled selected>Select GSDB</option>
                                    <option *ngFor="let gsdb of gsdbs" [value]="gsdb">{{gsdb}}</option>
                                </Select>
                            </div>
                            <div class="form-group market-class">
                                <button type="submit" id="search-button" (click)="selectMarket()">Submit</button>
                                <button class="ml-3" type="button" id="close-button" (click)="showDialogBox()" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                        <div class="col col-lg-2">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog [(visible)]="showDialog" [modal]="true" [responsive]="true" [closable]="false"
		  [style]="{width: '400px'}"
		  [baseZIndex]="10000">

	<p style="font-family: Ford Antenna">Do you want to exit the application ?</p>
	<p-footer>
		<button type="button" pButton icon="pi pi-check-circle" (click)="logout()"
				label="Yes"></button>
		<button type="button" pButton icon="pi pi-check-circle" (click)="navigateToSupplier()"
				label="No"></button>
	</p-footer>
</p-dialog>
