<div style="min-height: 88vh; ">
  <form (ngSubmit)="onSubmit()" style="padding: 0%;">
    <mat-card class="basecard  ">
      <div class="p-d-flex pb-2 mb-2  HeadTitle ">
        <div class="p-mr-2 ml-2">
          <mat-icon class="titleIcon"> home</mat-icon>
        </div>
        <div class="p-mr-2 orderHead">HOME</div>
      </div>
      <div class="p-fluid p-formgrid p-grid mt-2">
        <div class="p-field p-col-12 p-md-3  cardDiv " id="partSearch" style="cursor: pointer; ">
          <mat-card class="screenCard " routerLink="/dealer/dealer-part-search">
            <div class="p-d-flex p-flex-column">
              <div class="p-mb-2 screenHeader "> <span class="pt-2 pb-2 ">PARTS SEARCH</span>
              </div>
              <div class="p-mb-2 mt-4 mr-4 " style="text-align: center;">
                <mat-icon style="color: #234167; font-size: 50px; ">manage_search</mat-icon>
              </div>
            </div>
          </mat-card>
        </div>
        <div class="p-field p-col-12 p-md-3  cardDiv" id="backorderInquiry" style="cursor: pointer;">
          <mat-card class="screenCard" routerLink="/dealer/dealer-backorder">
            <div class="p-d-flex p-flex-column">
              <div class="p-mb-2 screenHeader "> <span class="pt-2 pb-2 ">BACKORDER INQUIRY</span>
              </div>
              <div class="p-mb-2 mt-4 mr-4 " style="text-align: center;">
                <mat-icon style="color: #234167; font-size: 50px; ">post_add</mat-icon>
                <!-- <i class="pi pi-check-circle" style="color: #234167; font-size: 3rem; "></i> -->
              </div>

            </div>
          </mat-card>
        </div>
        <div class="p-field p-col-12 p-md-3 cardDiv" id="supercessionInquiry" style="cursor: pointer;">
          <mat-card class="screenCard" routerLink="/dealer/dealer-supersession">
            <div class="p-d-flex p-flex-column">
              <div class="p-mb-2 screenHeader "> <span class="pt-2 pb-2 ">SUPERCESSION INQUIRY</span>
              </div>
              <div class="p-mb-2 mt-4 mr-4 " style="text-align: center;">
                <i class="pi pi-check-circle" style="color: #234167; font-size: 3rem; "></i>
                <!-- <mat-icon style="color: #234167; font-size: 50px; ">add_task</mat-icon> -->
              </div>
            </div>
          </mat-card>
        </div>
		  <div class="p-field p-col-12 p-md-3 cardDiv" id="partsInquiry" style="cursor: pointer;">
			  <mat-card class="screenCard "  routerLink="/dealer/parts-enquiry">
				  <div class="p-d-flex p-flex-column">
					  <div class="p-mb-2 screenHeader "> <span class="pt-2 pb-2 ">PART INQUIRY</span>
					  </div>
					  <div class="p-mb-2 mt-4 mr-4 " style="text-align: center;">
						  <mat-icon style="color: #234167; font-size: 50px; ">handyman</mat-icon>
					  </div>
				  </div>
			  </mat-card>
		  </div>
      </div>
    </mat-card>
  </form>

</div>
