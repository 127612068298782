<!-- Required for message service -->
<p-toast></p-toast>

<div class="container-fluid mt-3">
	<div class="progress-spinner" *ngIf="isSpinner==0">
		<p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
						   fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
		<div class="h5" style="color: #00095b; margin-top:3%">Loading Please Wait!</div>
	</div>
    <div class="card" *ngIf="isSpinner==1">
        <div class="m-1 p-2 text-center" id="table-heading-L15">Vendor Parts Summary</div>
        <p-table [value]="vendorDetails" #paginatortable
            styleClass="p-datatable-responsive-demo p-datatable-striped p-datatable-gridlines" [paginator]="true"
            [rows]="5" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            tableStyleClass="table-border" [rowsPerPageOptions]="[5,10,15]">

			<ng-template pTemplate="header">
                <tr>
                    <th style="width: 18%;" rowspan="2" id="L15-tbl-cell-heading">Service Part Number</th>
                    <th style="width: 14%;" rowspan="2" id="L15-tbl-cell-heading">Engineering Part Number</th>
					<th style="width: 13.5%;" rowspan="2" id="L15-tbl-cell-heading">Description</th>
					<th style="width: 11%;" rowspan="2" colspan="2" id="L15-tbl-cell-heading">
                        Behind Schedule
						<p-inputSwitch [(ngModel)]="behndShdSwitch"
									   (onChange)="onBehndShdSwitchChange($event)"></p-inputSwitch></th>
                    <th style="width: 6.35%; " rowspan="2" id="L15-tbl-cell-heading">Curr Month
						<p-inputSwitch [(ngModel)]="currMonthSwitch"
									   (onChange)="onCurrMonthSwitchChange($event)"></p-inputSwitch>
					</th>
					<th style="width: 7.05%; " rowspan="2" id="L15-tbl-cell-heading">Next 6 Months
						<p-inputSwitch [(ngModel)]="nextMonthSwitch"
									   (onChange)="onNextMonthSwitchChange($event)"></p-inputSwitch>
					</th>
                    <th id="L15-tbl-cell-heading" colspan="3" style="width: 31%;" class="text-center">Last Receipt</th>
                    <th style="width: 7%;" rowspan="2" id="L15-tbl-cell-heading">In Transit</th>
                    <th id="L15-tbl-cell-heading" rowspan="2" style="width: 9.65%">Comments</th>
                </tr>
                <tr>
                    <th id="sub-cell" class="text-center">Date</th>
                    <th id="sub-cell" class="text-center">Qty</th>
                    <th id="sub-cell" class="text-center">Pkg Slip</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td id="data-cell"><span class="p-column-title" (click)="invokePartModal()">Service Part Number</span><span
                            class="cell-content" data-toggle="modal"
                            data-target="#exampleModal">{{product.partNumber}}</span></td>
					<td id="data-cell"><span class="p-column-title">Engineering Part Number</span><span
						class="cell-content">{{product.epNo}}</span></td>
					<td id="data-cell"><span class="p-column-title">Description</span><span
                            class="cell-content">{{product.partDesc}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Behind Schedule</span><span class="cell-content"
                            style="float: right;">{{product.bhndSchd}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Behind Schedule_1</span><span
                            class="cell-content">{{product.boLowIndex}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Curr Month</span><span class="cell-content"
                            style="float: right;">{{product.currMonth}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Next Month</span><span class="cell-content"
                            style="float: right;">{{product.nextMonth}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Date</span><span
                            class="cell-content">{{product.rcptDate | date:'dd-MM-yyyy'}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Qty</span><span class="cell-content"
                            style="float: right;">{{product.rcptQty}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Pkg Slip</span><span class="cell-content"
                            style="float: right;">{{product.packSlip}}</span></td>
                    <td id="data-cell"><span class="p-column-title">In Transit</span><span class="cell-content"
                            style="float: right;">{{product.inProcess}}</span></td>
                    <td id="data-comment"><span class="p-column-title">Comments</span><span id="view-comment"
                            class="cell-content">
                            <button pButton pRipple data-toggle="modal" data-target="#viewComments"
                                class="p-button-rounded p-button-info p-ripple p-button p-component p-button-icon-only"
                                id="option" (click)="displayComments(product)"><i class="pi pi-exclamation-circle"
                                    style="font-size: 1.3rem;"></i></button>
                        </span></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <br>
	<div class="row" *ngIf="isSpinner==1">
		<div>
			<button class="btn btn_secondary m-2 p-2" id="download-button-l15"
					type="button" (click)="downloadData()">Download</button>
		</div>
		<div>
			<button class="btn btn_secondary m-2 p-2" id="cancel-button-l15"
					type="button" routerLink="/supplier/home">Cancel</button>
		</div>
	</div>
</div>

<!-- Modal to view Comments and Add comments -->
<div class="modal fade" id="viewComments" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid mt-3">
                    <div class="card">
                        <div class="m-1 p-2 text-center" id="table-heading">Comments History</div>
                        <p-table [value]="commentDetails"
                            styleClass="p-datatable-responsive-demo p-datatable-striped p-datatable-gridlines"
                            [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            tableStyleClass="table-border" [rowsPerPageOptions]="[5,10,15]">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 10%;" id="L15-cmt-tbl-cell-heading">User Type</th>
                                    <th style="width: 10%;" id="L15-cmt-tbl-cell-heading">User ID</th>
                                    <th style="width: 16%;" id="L15-cmt-tbl-cell-heading">Created Time</th>
                                    <th style="width: 64%;" id="L15-cmt-tbl-cell-heading">Comments</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-comment>
                                <tr>
                                    <td id="data-cell"><span class="p-column-title">User Type</span><span
                                            class="cell-content">{{comment.supUserType}}</span></td>
                                    <td id="data-cell"><span class="p-column-title">User ID</span><span
                                            class="cell-content">{{comment.supCreatedBy}}</span></td>
                                    <td id="data-cell"><span class="p-column-title">Created Time</span><span
                                            class="cell-content">{{comment.supCreatedTimestamp | date:'medium'}}</span>
                                    </td>
                                    <td id="data-cell"><span class="p-column-title">Comments</span><span
                                            class="cell-content">{{comment.supComments}}</span></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="alert alert-primary text-center mt-2" role="alert" *ngIf="isComment">
                        No comments Present!
                    </div>
                    <button type="button" style="float: left;" class="btn btn_secondary"
                        (click)="activateComment()">Add</button>
                    <button type="button" style="float: right;" class="btn btn_secondary"
                        (click)="closeModal()">Close</button>
                    <button type="button" id="close-comment-modal" hidden class="btn btn_secondary"
                        data-dismiss="modal">Close</button>
                    <br>
                    <div class="container form-text mt-4" id="add-comment" *ngIf="isAddComment">
                        <form #partComment="ngForm" id="comment-form" (ngSubmit)="submitComment()" novalidate>
                            <div class="alert alert-success text-center mt-2" role="alert" *ngIf="isCommentAdded">
                                Comment Added Successfully!
                            </div>
                            <div class="text-center h2 m-2 p-2 form-heading">Add Comment</div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Part Number: </label>
                                        <input type="text" id="" class="form-control" disabled
                                            [value]="productData?.partNumber">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Vendor Code: </label>
                                        <input type="text" id="" [value]="vendorCode" class="form-control" disabled>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Part Description: </label>
                                        <input type="text" id="" class="form-control" disabled
                                            [value]="productData?.partDesc">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Behind Schedule: </label>
                                        <input type="text" id="" [value]="productData?.bhndSchd" class="form-control"
                                            disabled>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Behind Schedule Index: </label>
                                        <input type="text" id="" class="form-control" disabled
                                            [value]="productData?.boLowIndex">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Current Month Qty: </label>
                                        <input type="text" id="" [value]="productData?.currMonth" class="form-control"
                                            disabled>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Next Month Qty: </label>
                                        <input type="text" id="" [value]="productData?.nextMonth" class="form-control"
                                            disabled>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Last Receipt Date: </label>
                                        <input type="text" id="" [value]="productData?.rcptDate | date:'dd-MM-yyyy'" class="form-control"
                                            disabled>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Last Receipt Qty: </label>
                                        <input type="text" id="" class="form-control" disabled
                                            [value]="productData?.rcptQty">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Last Receipt Pack Slip: </label>
                                        <input type="text" id="" [value]="productData?.packSlip" class="form-control"
                                            disabled>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">In Transit: </label>
                                        <input type="text" id="" [value]="productData?.inProcess" class="form-control"
                                            disabled>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Comments: </label>
                                        <textarea rows="4" cols="50" name="comments" id="comments" class="form-control"
                                            ngDefaultControl [(ngModel)]="comment" #comments="ngModel" name="comments"
                                            required></textarea>
                                        <small *ngIf="(comments.errors?.required && comments.touched) && !isCommentData"
                                            class="text-danger">*
                                            Please enter the Comments</small>
                                        <small *ngIf="isCommentData" class="text-danger">*
                                            Please enter a comment</small>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-center">
                                    <div class="form-group">
                                        <button type="submit" class="btn btn_secondary">Submit</button>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-center">
                                    <div class="form-group">
                                        <button type="button" class="btn btn_secondary" id="button-close"
                                            (click)="closeComment()">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

