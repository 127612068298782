<div class="example-container " (click)="hideProfile()" [class.example-is-mobile]="mobileQuery.matches">
    <mat-toolbar color="primary" class="example-toolbar">
        <button mat-icon-button (click)="snavweb.toggle()">
            <mat-icon>menu</mat-icon>
        </button>
        <a routerLink="/dashboard"><img src="/assets/image/logo5.jpg" alt="Ford" class="logo" width="100px"
                height="45px" style="margin-right: 3px; margin-top: 2px" /></a>
        <span class="line"></span>
        <h1 class="projectTitle">Pocket PANDA</h1>

        <span class="example-spacer"></span>

        <span class="username">{{ userId }}</span>

        <!-- <span class="fcdHome" (click)="fcdHome()" *ngIf="isActiveDirectory">
				FDS HOME
			</span> -->

        <span style="height: 23px; cursor: pointer" (click)="showProfile($event)">
            <mat-icon>account_circle</mat-icon>
        </span>
        <div *ngIf="isProfileClicked" class="userLogout" (click)="stopPropogation($event)">
            <div class="welcomeText">Welcome {{ userId }}!</div>
            <div class="welcomeText" *ngIf="!multiDealerEnable && !isActiveDirectory">
                Dealer : {{ dealerId }}
            </div>
            <div class="welcomeText" *ngIf="multiDealerEnable && !isActiveDirectory" style="cursor: pointer" (click)="swapDealer()">
                Dealer : <u>{{ dealerId }} </u>
            </div>
            <div class="p-d-flex ml-2" style="color: #102b4e" (click)="logout()">
                <div class="p-mr-2 mt-2" style="cursor: pointer">
                    <mat-icon>logout</mat-icon>
                </div>
                <div class="p-mr-2 mt-1" style="cursor: pointer; font-size: 14px">
                    <span>Logout</span>
                </div>
            </div>
        </div>
    </mat-toolbar>

    <mat-sidenav-container class="example-sidenav-container">
        <mat-sidenav #snavweb [opened]="mobileQuery.matches ? 'false' : 'true'"  (click)="closeSideNav()"
            [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
            class="sidenav mat-elevation-z4">
            <mat-nav-list class="sidenav-list">
                <a mat-list-item routerLink="/dealer/dashboard" class="navButton" id="home" routerLinkActive="active">
                    <mat-icon style="color: white">home</mat-icon>
                    <span class="sidenavTitle">Home</span>
                </a>
                <!-- <button class="navButton" style="margin-top: 10px;font-size: 14px;width:100%" routerLink="/dashboard" id="home" type="button"><mat-icon style="color: white;">home</mat-icon> HOME</button> -->

                <mat-divider class="mt-2" style="background: white; margin: 0px 10px 10px 10px"></mat-divider>

                <a mat-list-item (click)="navigateToPartSearch()" routerLink="/dealer/dealer-part-search" routerLinkActive="active" id="partSearch"
                    class="navButton" style="margin-top: 12px"> 
                    <mat-icon style="color: white">manage_search</mat-icon>
                    <span class="sidenavTitle">Parts Search</span>
                </a>

                <a mat-list-item (click)="navigateToBackOrder()" routerLink="/dealer/dealer-backorder" id="backorderInquiry" routerLinkActive="active"
                    class="navButton" style="margin-top: 12px">
                    <mat-icon style="color: white">post_add</mat-icon>
                    <span class="sidenavTitle"> Backorder Inquiry</span>
                </a>
                
                <a mat-list-item (click)="navigateToSupersession()" routerLink="/dealer/dealer-supersession"id="supercessionInquiry"
                    routerLinkActive="active" class="navButton" style="margin-top: 12px">
                    <!-- <mat-icon style="color: white">add_task</mat-icon> -->
                    <i class="pi pi-check-circle" style="color:white;font-weight: 600; "></i>
                    <span class="sidenavTitle">Supersession Inquiry</span>
                </a>
                <a mat-list-item (click)="navigateToPartInq()" id="partsInquiry" routerLink="/dealer/parts-enquiry" routerLinkActive="active"
                    class="navButton" style="margin-top: 12px">
                    <mat-icon style="color: white">handyman</mat-icon>
                    <span class="sidenavTitle">Part Inquiry</span>
                </a>
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content>
            <section class="content">
                <router-outlet></router-outlet>
                <div class="mainfooter">
                    <h6>
                        Copyright &copy; 2024 <a href="#">Ford Motor Company</a><span class="footer-line"></span>All rights
                        reserved.
                    </h6>
                </div>
            </section>

           
        </mat-sidenav-content>
    </mat-sidenav-container>
    
</div>



<p-dialog header="Choose Dealer" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '50vw' }"
    [baseZIndex]="10000" [closable]="false" [draggable]="false" [resizable]="false">
    <div *ngFor="let category of dealerCode" class="p-field-checkbox">
        <p-radioButton [inputId]="category" name="category" [value]="category" [(ngModel)]="selectedCategory">
        </p-radioButton>
        <label [for]="category">{{ category }}</label>
    </div>
    <div>
        <small id="username2-help" class="errorMessage" id="errorMessage" *ngIf="errorMsg">Select Dealer Code to Proceed
            further
        </small>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-chevron-right" (click)="setDealer()" label="Proceed" styleClass="p-button-text">
        </p-button>
    </ng-template>
</p-dialog>