
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService,Message } from 'primeng/api';
import { LazyLoadEvent } from 'primeng/api';
import { CommonService } from '../services/common.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import { Observable, observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import {MenuModule} from 'primeng/menu';
import {MenuItem} from 'primeng/api';
import { formatDate } from '@angular/common';
interface AdviseCode {
  name: string;
  code: string;
}
interface OrderType {
  name: string;
  code: string;
}
@Component({
  selector: 'back-order-status',
  templateUrl: './back-order-status.component.html',
  styleUrls: ['./back-order-status.component.scss'],
  providers: [ConfirmationService],
})
export class BackOrderStatusComponent implements OnInit {
  adviseCodes: AdviseCode[];
  orderType:OrderType[];
  rangeDates: Date[];
  items: MenuItem[];
  showmobFilter: boolean = true;
  showFilter: boolean = true;
  dealerOptions: boolean = false;
  enableClearadviceCodeFilter:boolean=false;
  enableClearQty:boolean=false;
  enableClearitemFilter:boolean=false;
  enableClearregisterNumberFiter:boolean=false;
  enableClearordeNoFilter:boolean=false;
  enableCleartypeFilter:boolean=false;
  partNumberOptions: boolean = false;
  valid: boolean = false;
  dealerBackorderRequestForm: FormGroup;
  orderList: any[];
  partOrderList: any[];
  dealerList:any;
  orderDetailTableEnable: boolean;
  partDetailTableEnable: boolean;
  submitted = false;
  cols: any[];
  partCols: any[];
  tableVal = [];
  backOrderList = [];
  backOrderListExcel = [];
  totalRecords :string
  first = 0;
  date1: Date;
  rows = 5;
  showPage:boolean=false;
  checked: boolean;
  datatableRequest:any;
  datatableRequestExcel:any;
  marked = false;
  accepted: boolean = true;
  rowsPerPageOptions = 5;
  country=sessionStorage.getItem('country');
  paymentOptions: any[];
  exportColumns: any[];
  dealerChanged = false;
  showError: boolean = false;
  searched: boolean = false;
  showErrorMsg: boolean = false;
  loading: boolean;
  fecthedPart:any;
  backActive:any;
  curURL:any;
  showIcon: boolean = true;
  filteredAdviceCode: string = '';
  filteredType:any;
  partntypeFiltermval:any;
  ordeNoFilter:any;
  typeFilter:any;
  registerNumberFiter:any;
  itemFilter:any;
  adviceCodeFilter:any;
  quantityOrderFilter:any;
  isSpinner: any = 0;
  rowData:any;
  msgs: Message[];
  utcDate: string;
  constructor(private fb: FormBuilder,public activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
    private messageService:MessageService,
    private navigation: NavigationService) 
    {
    this.adviseCodes = [
    { name: '5', code: 'CAN' },
    { name: '10', code: 'B/O' },
    { name: '12', code: 'DEF' }];
    this.orderType=[
      {name:'1E',code:'1E'},
      {name:'2E',code:'2E'},
      {name:'3E',code:'3E'}
    ]
    }
  ngOnInit(): void {
    
   this.msgs=[];
   
    this.backActive = this.navigation.isBackActive;
    this.initialiseDealerOrderInquiryForm();
    this.activatedRoute.params.subscribe((params) => {
      if (params && params.partNumber && params.partNumber.trim() !== '') 
      {
        this.dealerBackorderRequestForm.controls['partNumber'].setValue(params.partNumber);
        this.searchDealer();
      }
    });
   
  	if (this.commonService.backorderFilter && Object.keys(this.commonService.backorderFilter).length > 0)
	  {
	    this.restoreFilter();
	    this.backActive=false;
	  }
    else
    {
      this.showFilter = true;
    }
    this.getDateTime();
    
    }
    getDateTime()
    {
      const today = new Date();
      this.utcDate=formatDate(today, 'dd/MM/yyyy hh:mm a', 'en-US', 'UTC+4');
     
      console.log("utcdate",this.utcDate);
    }
    initialiseDealerOrderInquiryForm()
    {
      this.dealerBackorderRequestForm = this.fb.group({
      adviceCode: [''],
      partNumber: [''],
      type: [''],
      status: [''],
      searchField: [''],
      dealer: [''],
      ispart: [false],
      typeFilter: [],
      ordeNoFilter: [],
      registerNumberFiter: [],
      itemFilter: [],
      adviceCodeFilter: [],
      quantityOrderFilter: [],
    });
    this.items = [
      {
        label: 'Supersession Inquiry', icon: 'pi pi-check-circle',
        command: (event) => {
        this.navigation.isBackActive = true;
        this.navigation.setUrl('dealer/dealer-backorder');
        this.commonService.backorderFilter =this.dealerBackorderRequestForm.getRawValue();
        this.commonService.filteredType=this.filteredType;
        const partno = this.rowData.partNo.replace(/\s/g, '');
        this.router.navigate(['dealer-supersession/' + partno]);
      }
      }
      
  ];
 
  }
  selectedrow(rowData)
  {
    this.rowData=rowData;
  }

  
  submit()
  {
    this.submitted = true;
    this.searchDealer();
  }
  searchDealer() 
  {
  	this.loading = true;
    this.showErrorMsg = false;
    this.searched = true;
    this.showFilter = false;
    this.showmobFilter = true;
    this.valid = true;
    this.fecthedPart =this.dealerBackorderRequestForm.value.partNumber.replace(/\s/g, '');
    if(this.dealerBackorderRequestForm.value.adviceCode==='10')
    {
		this.filteredAdviceCode = 'B/O';
  	}
    if(this.dealerBackorderRequestForm.value.adviceCode==='5')
    {
		  this.filteredAdviceCode = 'CAN';
	  }
    if(this.dealerBackorderRequestForm.value.adviceCode==='12')
    {
		  this.filteredAdviceCode = 'DEF';
	  }
	  this.filteredType = this.dealerBackorderRequestForm.value.type;
    this.processBackOrder(0, 'partNumber', 1);
  }

  processBackOrder(startingRecordNo, sortF, sortO) 
  {
    let partRefNo = '';
    if (this.dealerBackorderRequestForm.value.partNumber) 
    {
      this.commonService.fetchReferanceNo(this.fecthedPart.toUpperCase()).subscribe((data) => {
          if (data !== null) 
          {
            partRefNo = '' + data + '';
            this.fetchBackOrder(partRefNo, startingRecordNo, sortF, sortO);
          } 
          else 
          {
            this.loading = false;
            this.backOrderList = [];
            //  this.showError = true;
             this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No records found. Please check the search criteria', id: 'pop-up-message', life: 5000 });
          }
        });
    } 
    else
    {
      this.fetchBackOrder(partRefNo, startingRecordNo, sortF, sortO);
    }
  }

  fetchBackOrder(partRefNo, startingRecordNo, sortF, sortO)
  {

    if (this.dealerBackorderRequestForm.value.partNumber || this.dealerBackorderRequestForm.value.type
      || this.dealerBackorderRequestForm.value.adviceCode) {
        
      this.datatableRequest = {
        filters: {
          refNo: partRefNo,
          dealerCode: sessionStorage['dealerCode'],
          orderNumber: this.dealerBackorderRequestForm.value.ordeNoFilter,
          item: this.dealerBackorderRequestForm.value.itemFilter,
          // type: this.dealerBackorderRequestForm.value.typeFilter,
          type: this.dealerBackorderRequestForm.value.typeFilter?this.dealerBackorderRequestForm.value.typeFilter:this.dealerBackorderRequestForm.value.type,
          registerNo:this.dealerBackorderRequestForm.value.registerNumberFiter,
          quantityOrder:this.dealerBackorderRequestForm.value.quantityOrderFilter,
          adviceCode: this.dealerBackorderRequestForm.value.adviceCodeFilter?this.dealerBackorderRequestForm.value.adviceCodeFilter:this.dealerBackorderRequestForm.value.adviceCode,
        },
        startingRecordNumber: startingRecordNo,
        sortField: sortF==undefined?'partNumber':sortF,
        sortOrder: sortO,
      };
      this.datatableRequestExcel = {
        filters: {
          refNo: partRefNo,
          dealerCode: sessionStorage['dealerCode'],
          orderNumber: this.dealerBackorderRequestForm.value.ordeNoFilter,
          item: this.dealerBackorderRequestForm.value.itemFilter,
          type: this.dealerBackorderRequestForm.value.typeFilter?this.dealerBackorderRequestForm.value.typeFilter:this.dealerBackorderRequestForm.value.type,
          registerNo:this.dealerBackorderRequestForm.value.registerNumberFiter,
          quantityOrder:this.dealerBackorderRequestForm.value.quantityOrderFilter,
          adviceCode: this.dealerBackorderRequestForm.value.adviceCodeFilter?this.dealerBackorderRequestForm.value.adviceCodeFilter:this.dealerBackorderRequestForm.value.adviceCode,
        },
        startingRecordNumber: 0,
        sortField: sortF==undefined?'partNumber':sortF,
        sortOrder: sortO,
      };
      this.getTableData();
    }
    else 
    {
       this.showErrorMsg = true;
      // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Kindlydd select the Part Number', id: 'pop-up-message', life: 5000 });
    }
  }

  getTableData() 
  {
    this.commonService.fetchBackOrder(this.datatableRequest).subscribe((data) => {
        this.responseHandler(data);
        this.loading = false;
        this.showFilter = false;
        this.commonService.backorderFilter = [];
      });
     
  }
  getTableDataFilter() 
  {
    this.commonService.fetchBackOrder(this.datatableRequest).subscribe((data) => {
        this.responseHandlerFilter(data);
        this.loading = false;
        this.showFilter = false;
        this.commonService.backorderFilter = [];
      },
      err => {
        if(err.status==500)
        {
          this.dealerBackorderRequestForm.get('itemFilter').setValue("");
       this.dealerBackorderRequestForm.get('ordeNoFilter').setValue("");
       this.dealerBackorderRequestForm.get('typeFilter').setValue("");
       this.dealerBackorderRequestForm.get('quantityOrderFilter').setValue("");
       this.dealerBackorderRequestForm.get('registerNumberFiter').setValue("");
       this.dealerBackorderRequestForm.get('adviceCodeFilter').setValue("");
       this.loading = false;
        this.showFilter = false;
        this.commonService.backorderFilter = [];
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No record found. Please check the search criteria', id: 'pop-up-message', life: 5000 });
        }
        
      });
     
  }
  responseHandlerFilter(response) 
  {
    if (response.recordsTotal === 0)
     {
       this.dealerBackorderRequestForm.get('itemFilter').setValue("");
       this.dealerBackorderRequestForm.get('ordeNoFilter').setValue("");
       this.dealerBackorderRequestForm.get('typeFilter').setValue("");
       this.dealerBackorderRequestForm.get('quantityOrderFilter').setValue("");
       this.dealerBackorderRequestForm.get('registerNumberFiter').setValue("");
       this.dealerBackorderRequestForm.get('adviceCodeFilter').setValue("");
       this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No records found. Please check the search criteria', id: 'pop-up-message', life: 5000 });
      }
    else
    {
    this.backOrderList = response.data;
    this.showPage=true;
    this.totalRecords = response.recordsTotal;
    this.rowsPerPageOptions = 10;
  }
  }

  


  responseHandler(response) 
  {
    if (response.recordsTotal === 0)
     {
      //  this.showErrorMsg = true;
      this.showError = true;
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No records found. Please check the search criteria', id: 'pop-up-message', life: 5000 });
     }
    console.log(response.data)
    this.backOrderList = response.data;
    this.showPage=true;
    this.totalRecords = response.recordsTotal;
    this.rowsPerPageOptions = 10;
  }
 
  loadOrderDetail(event: LazyLoadEvent) {
    console.log(event);
    this.loading = true;
	  let order = 1;
    if (event.sortOrder < 0) {
      order = 0;
    }
    let startNo = event.first;
    let sortId = event.sortField !== null ? event.sortField : 'partNumber';
    this.processBackOrder(startNo, sortId, order)

  }
  sendFilterVal(event: LazyLoadEvent) {
    console.log(event);
    this.loading = true;
    let order = 1;
    if (event.sortOrder < 0) 
    {
      order = 0;
    }
    // let startNo = event.first;
    // let sortId = event.sortField !== null ? event.sortField : 'partNumber';
    this.dealerBackorderRequestForm.value.registerNumberFiter;
    this.dealerBackorderRequestForm.value.adviceCodeFilter;
    this.dealerBackorderRequestForm.value.quantityOrderFilter;
    if(this.dealerBackorderRequestForm.value.partNumber)
    {

      this.commonService.fetchReferanceNo(this.fecthedPart).subscribe((data) => {
      console.log('****** Ref No *********');
      console.log(data);
      if (data != null)
      {
        const partRefNo = '' + data + '';
        this.datatableRequest = {
        filters: 
        {
          refNo: partRefNo,
          dealerCode: sessionStorage['dealerCode'],
          orderNumber: this.dealerBackorderRequestForm.value.ordeNoFilter,
          item: this.dealerBackorderRequestForm.value.itemFilter,
          type: this.dealerBackorderRequestForm.value.typeFilter?this.dealerBackorderRequestForm.value.typeFilter:this.dealerBackorderRequestForm.value.type,
          registerNo:this.dealerBackorderRequestForm.value.registerNumberFiter,
          quantityOrder:this.dealerBackorderRequestForm.value.quantityOrderFilter,
          adviceCode: this.dealerBackorderRequestForm.value.adviceCodeFilter?this.dealerBackorderRequestForm.value.adviceCodeFilter:this.dealerBackorderRequestForm.value.adviceCode,
        },
          startingRecordNumber: 0,
          sortField: 'partNumber',
          sortOrder: 1,
        };
        this.datatableRequestExcel = {
          filters: {
            refNo: partRefNo,
            dealerCode: sessionStorage['dealerCode'],
            orderNumber: this.dealerBackorderRequestForm.value.ordeNoFilter,
            item: this.dealerBackorderRequestForm.value.itemFilter,
            type: this.dealerBackorderRequestForm.value.typeFilter?this.dealerBackorderRequestForm.value.typeFilter:this.dealerBackorderRequestForm.value.type,
            registerNo:this.dealerBackorderRequestForm.value.registerNumberFiter,
            quantityOrder:this.dealerBackorderRequestForm.value.quantityOrderFilter,
            adviceCode: this.dealerBackorderRequestForm.value.adviceCodeFilter?this.dealerBackorderRequestForm.value.adviceCodeFilter:this.dealerBackorderRequestForm.value.adviceCode,
          },
          startingRecordNumber: 0,
          sortField: 'partNumber',
          sortOrder: 1,
        };
        this.getTableDataFilter();
      } 

      else
      {
        this.loading = false;
         this.backOrderList = [];
        // this.showError = true;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: ' the Partsss Number', id: 'pop-up-message', life: 5000 });
      }
    });
  }
  else{
        
    this.datatableRequest = {
      
      filters: {
        refNo: '',
        dealerCode: sessionStorage['dealerCode'],
        orderNumber: this.dealerBackorderRequestForm.value.ordeNoFilter,
        item: this.dealerBackorderRequestForm.value.itemFilter,
        type: this.dealerBackorderRequestForm.value.typeFilter?this.dealerBackorderRequestForm.value.typeFilter:this.dealerBackorderRequestForm.value.type,
        registerNo:this.dealerBackorderRequestForm.value.registerNumberFiter,
        quantityOrder:this.dealerBackorderRequestForm.value.quantityOrderFilter,
        adviceCode: this.dealerBackorderRequestForm.value.adviceCodeFilter?this.dealerBackorderRequestForm.value.adviceCodeFilter:this.dealerBackorderRequestForm.value.adviceCode,
      },
      startingRecordNumber: 0,
      sortField: 'partNumber',
      sortOrder: 1,
    };
  
    this.datatableRequestExcel = {
      filters: {
        refNo: '',
        dealerCode: sessionStorage['dealerCode'],
        orderNumber: this.dealerBackorderRequestForm.value.ordeNoFilter,
        item: this.dealerBackorderRequestForm.value.itemFilter,
        type: this.dealerBackorderRequestForm.value.typeFilter?this.dealerBackorderRequestForm.value.typeFilter:this.dealerBackorderRequestForm.value.type,
        registerNo:this.dealerBackorderRequestForm.value.registerNumberFiter,
        quantityOrder:this.dealerBackorderRequestForm.value.quantityOrderFilter,
        adviceCode: this.dealerBackorderRequestForm.value.adviceCode,
      },
      startingRecordNumber: 0,
      sortField: 'partNumber',
      sortOrder: 1,
    };
    this.getTableDataFilter();
  }
 
  }
  

  
 
  
  showFilterOpt() 
  {
    this.showFilter = true;
    this.showmobFilter = false;
  }
  getExcelData()
  {
    this.isSpinner = 1; // To stop spinner
    this.commonService.fetchBackOrderExcel(this.datatableRequestExcel).subscribe((data) => {
    this.responseHandlerExcel(data);
      
    });
  }
  responseHandlerExcel(response) {
    this.backOrderListExcel = response.data;
    if(this.backOrderListExcel)
    {
      this.isSpinner=0;
      this.exportExcel();
    }
    else
    {
      console.log("EXCEL ERROR")
    }
    
  }
  exportExcel() {
      
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.backOrderListExcel);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'backOrderList');
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((filersaver) => {
      const EXCEL_TYPE ='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      filersaver.saveAs(
        data,
        fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }
  restoreFilter() {
    this.dealerBackorderRequestForm.get('partNumber').setValue(this.commonService.backorderFilter?.partNumber);
    this.dealerBackorderRequestForm.get('adviceCode').setValue(this.commonService.backorderFilter?.adviceCode);
    this.dealerBackorderRequestForm.get('itemFilter').setValue(this.commonService.backorderFilter?.itemFilter);
       this.dealerBackorderRequestForm.get('ordeNoFilter').setValue(this.commonService.backorderFilter?.ordeNoFilter);
       this.dealerBackorderRequestForm.get('typeFilter').setValue(this.commonService.backorderFilter?.typeFilter);
       this.dealerBackorderRequestForm.get('quantityOrderFilter').setValue(this.commonService.backorderFilter?.quantityOrderFilter);
       this.dealerBackorderRequestForm.get('registerNumberFiter').setValue(this.commonService.backorderFilter?.registerNumberFiter);
       this.dealerBackorderRequestForm.get('adviceCodeFilter').setValue(this.commonService.backorderFilter?.adviceCodeFilter);
	  this.fecthedPart = this.dealerBackorderRequestForm.get('partNumber').value.replace(/\s/g, '');
	  this.dealerBackorderRequestForm.get('type').setValue(this.commonService.filteredType);
  	this.filteredType=this.commonService.filteredType;
  	if(this.commonService.backorderFilter?.adviceCode)
  	{
      if(this.commonService.backorderFilter?.adviceCode==='10')
      {
			this.filteredAdviceCode = 'B/O';
	  	}
      if(this.commonService.backorderFilter?.adviceCode==='5')
      {
			  this.filteredAdviceCode = 'CAN';
		  }
      if(this.commonService.backorderFilter?.adviceCode==='12')
      {
			  this.filteredAdviceCode = 'DEF';
		  }
  	}
	  this.fecthedPart =this.dealerBackorderRequestForm.get('partNumber').value;
    this.processBackOrder('partNumber', 0, 1);
  }
 

  navToPartInq(rowData)
   {
    this.navigation.isBackActive = true;
    const partno = rowData.partNo.replace(/\s/g, '');
  	this.commonService.backorderFilter = this.dealerBackorderRequestForm.getRawValue();
  	this.commonService.filteredType=this.filteredType;
  	this.navigation.setUrl('dealer/dealer-backorder');
    this.router.navigate(['parts-enquiry/' + partno]);
   }
  navToPartSearch() 
   {
    this.curURL = this.navigation.fetchUrl();
    this.router.navigate([this.navigation.fetchUrl()]);
   }
 
  formatPartNumber() 
  {
    let part = this.dealerBackorderRequestForm.value.partNumber.replace(/\s/g, '').toUpperCase();
    let partnumval=part.replace(/[^a-zA-Z 0-9]+/g,"");
    this.dealerBackorderRequestForm.controls['partNumber'].setValue(partnumval);
  
   
  }
  formatadviceCodeFilter()
  {
    if(this.dealerBackorderRequestForm.value.adviceCodeFilter)
    {
      this.enableClearadviceCodeFilter=true;
    let advCode = this.dealerBackorderRequestForm.value.adviceCodeFilter.replace(/\s/g, '').toUpperCase();
    let advCodeFormatted=advCode.replace(/[^a-zA-Z 0-9]+/g,"");
    this.dealerBackorderRequestForm.controls['adviceCodeFilter'].setValue(advCodeFormatted);
  }

    else
      {
        this.enableClearadviceCodeFilter=false;
      }
  }
  formatOrderFilter()
  {
    if(this.dealerBackorderRequestForm.value.ordeNoFilter)
      {
        this.enableClearordeNoFilter=true;
    let orderNum = this.dealerBackorderRequestForm.value.ordeNoFilter.replace(/\s/g, '').toUpperCase();
    let orderNumFormatted=orderNum.replace(/[^a-zA-Z 0-9]+/g,"");
    this.dealerBackorderRequestForm.controls['ordeNoFilter'].setValue(orderNumFormatted);
      }
      else
      {
        this.enableClearordeNoFilter=false;
      }

  }
formatregisterNumberFilter()
{
  if(this.dealerBackorderRequestForm.value.registerNumberFiter)
      {
        this.enableClearregisterNumberFiter=true;
  let registerNo = this.dealerBackorderRequestForm.value.registerNumberFiter.replace(/\s/g, '').toUpperCase();
    let registerNoFormatted=registerNo.replace(/[^a-zA-Z 0-9]+/g,"");
    this.dealerBackorderRequestForm.controls['registerNumberFiter'].setValue(registerNoFormatted);
}
else
      {
        this.enableClearregisterNumberFiter=false;
      }
}

  formatitemFilter()
  {
    if(this.dealerBackorderRequestForm.value.itemFilter)
      {
        this.enableClearitemFilter=true;
        let item = this.dealerBackorderRequestForm.value.itemFilter.replace(/\s/g, '').toUpperCase();
        let itemvalFormatted=item.replace(/[^a-zA-Z 0-9]+/g,"");
        this.dealerBackorderRequestForm.controls['itemFilter'].setValue(itemvalFormatted);
      }
      else
      {
        this.enableClearitemFilter=false;
      }
      
  }
  formattypeFilter()
    {
      if(this.dealerBackorderRequestForm.value.typeFilter)
      {
        this.enableCleartypeFilter=true;
        let type = this.dealerBackorderRequestForm.value.typeFilter.replace(/\s/g, '').toUpperCase();
        let typevalFormatted=type.replace(/[^a-zA-Z 0-9]+/g,"");
        this.dealerBackorderRequestForm.controls['typeFilter'].setValue(typevalFormatted);

      }
      else
      {
        this.enableCleartypeFilter=false;
      }
}
  
  formatquantityOrderFilter()
  {
    if(this.dealerBackorderRequestForm.value.quantityOrderFilter)
      {
        this.enableClearQty=true;let qty = this.dealerBackorderRequestForm.value.quantityOrderFilter.replace(/\s/g, '').toUpperCase();
        let qtyvalFormatted=qty.replace(/[^a-zA-Z 0-9 /]+/g,"");
        this.dealerBackorderRequestForm.controls['quantityOrderFilter'].setValue(qtyvalFormatted);
      }
    else
      {
        this.enableClearQty=false;
      }
}
  clearOrderFilter()
  {
    
      this.dealerBackorderRequestForm.get('ordeNoFilter').setValue("");
      this.enableClearordeNoFilter=false;
      }
    clearItemFilter()
    {
      this.dealerBackorderRequestForm.get('itemFilter').setValue("");
      this.enableClearitemFilter=false;
    }
    clearRegisterFilter()
    {
      this.dealerBackorderRequestForm.get('registerNumberFiter').setValue("");
      this.enableClearregisterNumberFiter=false;
    }
    clearTypeFilter()
    {
      
      this.dealerBackorderRequestForm.get('typeFilter').setValue("");
      this.enableCleartypeFilter=false;
      

    }
    clearQtyFilter()
    {
      this.dealerBackorderRequestForm.get('quantityOrderFilter').setValue("");
      this.enableClearQty=false;

    }
    clearadvFilter()
    {
      this.dealerBackorderRequestForm.get('adviceCodeFilter').setValue("");
      this.enableClearadviceCodeFilter=false;

    }


  clear() {
    this.showError = false;
    this.initialiseDealerOrderInquiryForm();
    this.submitted = false;
    this.searched = false;
    this.dealerOptions = false;
    this.valid = false;
   
    this.orderDetailTableEnable = false;
    this.filteredAdviceCode="";
    this.partDetailTableEnable = false;
    
    this.accepted = false;
  
    this.backOrderList = [];
    this.totalRecords="";
    this.showPage=false;
    this.messageService.clear();  
    
    
  }

  onReject(){
    
  }
}
