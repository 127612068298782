import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ConfirmEventType, MessageService, SelectItem } from 'primeng/api';
import { Announcement } from '../model/announcement';
import { AdminStackService } from '../services/admin-stack.service';
import { AdminStackModel } from './model/AdminStackModel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { HomeService } from '../services/home.service';
import { Market } from '../model/market';
import { NgForm } from '@angular/forms';
import { MarketData } from '../model/marketData';
import { DatePipe, DOCUMENT, formatDate } from '@angular/common';
@Component({
  selector: 'admin-stack',
  templateUrl: './admin-stack.component.html',
  styleUrls: ['./admin-stack.component.scss']
})
export class AdminStackComponent implements OnInit {

  // Form Variables
  @ViewChild('adminStack') adminStack: NgForm;
  fromDate: Date;
  toDate: Date;
  comment: string;
  marketName: string;
  announcementTitle: string;
  createdDate: Date;
  markets: Market[];
  selectedMarkets: any;
  submitFormData: boolean = false;
  today: Date;
  startDateDisable: boolean = false;
  endDateDisable: boolean = true;
  adminStackModel: AdminStackModel;
  editFlag: string;
  isMultiselectTouched: boolean;
  
  // to display announcement details 
  announcementDialog: boolean;
  dispAnnouncement: AdminStackModel = null;

  idValue: number;
  
  // Admin Table 
  adminModelList: AdminStackModel[] = [];
  first: number = 0;
  rows: number = 5;

  
  // CDSID & Market data
  cdsid: { id : string, isCovisint: boolean} = {
		id: '',
		isCovisint: false
	};
  marketData: MarketData;
  minDate:Date;
  constructor(
    private adminStackService: AdminStackService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private homeService: HomeService,
    private datePipe:DatePipe) {  }

  ngOnInit() {
    this.minDate = new Date();
    this.editFlag = 'N';
    this.cdsid = JSON.parse(sessionStorage.getItem("cdsid"));
    this.marketData = JSON.parse(sessionStorage.getItem("market-data"));
    this.homeService.getMarket(this.cdsid.id).subscribe(response => {
      this.markets = this.homeService.setMarketData(response.filter((data: string) => data.toUpperCase() !== "SBOM"));
    })
    this.today = new Date();
    this.getAdminDataList();
  }

  getAdminDataList(){
    this.adminStackService.getAllAdminDetails().subscribe((response: AdminStackModel[]) => {
      response.sort((a,b) => +new Date(b.createdDate) - +new Date(a.createdDate));
      this.adminModelList = response
    })
  }

  onDateChange(){
    this.endDateDisable = false;
  }

  actionSubmit() {
    if(this.fromDate && this.toDate && this.marketName && this.announcementTitle && this.comment){
      this.submitFormData = false;
      this.adminStackModel = new AdminStackModel;
      this.adminStackService.getMaxValueForInsertAdmin().subscribe(response => {
        if (response != null && response.toString().trim().length > 0) {
          this.adminStackModel.admSak = Number(response) + 1;
        } else {
          this.adminStackModel.admSak = 1;
        }
        if (this.editFlag === 'Y') {
          this.adminStackModel.admSak = this.idValue;
        }
        this.adminStackModel.toDate =this.datePipe.transform(this.toDate,'yyyy-MM-dd');
        this.adminStackModel.fromDate = this.datePipe.transform(this.fromDate,'yyyy-MM-dd');
        this.adminStackModel.market = this.marketName;
        this.adminStackModel.commentTitle = this.announcementTitle;
        // this.adminStackModel.createdDate = new Date(); //formatDate(this.today, 'dd-MM-yyyy hh:mm a', 'en-US', '+0530');
        this.adminStackModel.comment = this.comment;
        this.adminStackModel.userComment = this.cdsid.id;
        let isStartDateGreater: boolean;
        let fromDate = new Date(this.fromDate);
        let toDate = new Date(this.toDate);
        if(fromDate.getFullYear()===toDate.getFullYear()){
          if(fromDate.getMonth()===toDate.getMonth()){
            isStartDateGreater = fromDate.getDate()<=toDate.getDate() ? false : true;
          }else if (fromDate.getMonth()>toDate.getMonth()){
            isStartDateGreater =  true;
          }else if (fromDate.getMonth()<toDate.getMonth()){
            isStartDateGreater = false;
          }
        }else if(fromDate.getFullYear()<toDate.getFullYear()){
          isStartDateGreater = false;
        }else if(fromDate.getFullYear()>toDate.getFullYear()){
          isStartDateGreater = true;
        }
        if(!isStartDateGreater){
          if (this.editFlag === 'N') {
            this.adminStackModel.createdDate = new Date(); 
            this.adminStackModel.lastUpdatedTimeStamp = new Date();
            this.adminStackService.createAdminDetails(this.adminStackModel)
              .subscribe(data => {
                this.getAdminDataList();
                this.successMsg();
                this.clearall();
              });
          } else { 
            this.adminStackModel.lastUpdatedTimeStamp = new Date();
            this.adminStackModel.createdDate = new Date(this.createdDate);
            this.updateConfirmation(); 
          }
        }else{
          this.messageService.add({ severity: 'error', summary: 'Error', id: 'pop-up-message', detail: 'End Date must be greater than start date', life: 5000 });
        }
      })
      }else{
      this.submitFormData = true;
    }
  }

  successMsg(){
    if (this.editFlag === "N") {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Announcement Created Successfully', id: 'pop-up-message', life: 5000 });
    } else {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Announcement Updated Successfully', id: 'pop-up-message', life: 5000 });
    }
  }

  clearall() {
    this.adminStack.reset();
    this.startDateDisable = false;
    this.editFlag = "N";
    this.endDateDisable = true; 
    this.submitFormData = false;
    this.isMultiselectTouched = false;
  }

  changeMarket(event){
    let selectedMarkets: Market[] = event.value;
    this.setMarketList(selectedMarkets);
  }

  setMarketList(selectedMarkets: Market[]){
    if(selectedMarkets.length>0){
      let markets: string[] = selectedMarkets.map(data => data.marketName);
      this.marketName = markets.join();
    }
  }

  updateValue() {
    this.adminStackService.createAdminDetails(this.adminStackModel)
      .subscribe(data => {
        this.getAdminDataList();
        this.successMsg();
        this.clearall();
      });
  }

  editAnnouncement(announcement: AdminStackModel) {
    let isStartDate: boolean = this.checkDateLesser(announcement.fromDate);
    let isEndDate: boolean = this.checkDateLesser(announcement.toDate);
    if(isEndDate){
      this.clearall();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Past dates are not allowed to edit', id: 'pop-up-message', life: 5000 })
    }else{
      this.startDateDisable = isStartDate;
      this.endDateDisable = isEndDate;
      this.fromDate = new Date(announcement.fromDate)
      this.toDate =new Date(announcement.toDate);
      this.comment = announcement.comment;    
      this.idValue = announcement.admSak;
      this.announcementTitle = announcement.commentTitle;
      this.editFlag = 'Y';
      this.createdDate = announcement.createdDate;
      this.selectedMarkets = this.homeService.setMarketData(announcement.market.split(','));
      this.setMarketList(this.selectedMarkets);
      document.getElementById('top').scrollIntoView();
    }
  }

  checkDateLesser(date){
    let today: Date = new Date();
    let checkDate: Date = new Date(date);
    if(checkDate.getFullYear()===today.getFullYear()){
      if(checkDate.getMonth()===today.getMonth()){
        return checkDate.getDate()<today.getDate() ? true : false;
      }else if (checkDate.getMonth()>today.getMonth()){
        return false;
      }else if (checkDate.getMonth()<today.getMonth()){
        return true;
      }
    }else if(checkDate.getFullYear()<today.getFullYear()){
      return true;
    }else if(checkDate.getFullYear()>today.getFullYear()){
      return false;
    }
    return false;
  }

  displayAnnouncement(announcement: AdminStackModel){
    this.dispAnnouncement = announcement;
  }

  deleteAnnouncement(announcement: AdminStackModel) {
    this.adminStackService.deleteAdminDetails(announcement.admSak)
      .subscribe(data => {
        this.getAdminDataList();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Announcement Deleted Successfully', id: 'pop-up-message', life: 5000 });
      });

  }

  //  Confirmation Message Box
  deleteConfirmation(announcement) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteAnnouncement(announcement)
      },
      reject: () => {
        this.clearall()
      }
    });
  }

  updateConfirmation() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to update?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.updateValue();
      },
      reject: () => {
        // this.clearall()
        this.adminStack.reset();
      }
    });
  }
  check(multiselect){
    this.isMultiselectTouched = true;
  }

  //  Table Pagination
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.adminModelList ? this.first === (this.adminModelList.length - this.rows): true;
  }

  isFirstPage(): boolean {
    return this.adminModelList ? this.first === 0 : true;
  }

}
