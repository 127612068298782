import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExamplePageComponent } from './example-page/example-page.component';
import { OAuthCallbackComponent } from '././oauth-callback/oauth-callback.component';

import { DealerModule } from './dealer/dealer.module';
import { SupplierModule } from './supplier/supplier.module';
import { UnauthorizedUserComponent } from './unauthorized-user/unauthorized-user.component';
import { CommonLandingComponent } from './common-landing/common-landing.component';
import { DashobardComponent } from './dealer/Dashobard/Dashobard.component';
import { PartSearchComponent } from './dealer/part-search/part-search.component';
import { PartsInquiryComponent } from './dealer/parts-inquiry/parts-inquiry.component';
import { SupercessionInquiryComponent } from './dealer/supercession-inquiry/supercession-inquiry.component';
import { BackOrderStatusComponent } from './dealer/back-order-status/back-order-status.component';
import { HomeComponent } from './supplier/home/home.component';
import { PartOrderStatusComponent } from './supplier/part-order-status/part-order-status.component';
import { AdminStackComponent } from './supplier/admin-stack/admin-stack.component';
import { VendorPartStatusComponent } from './supplier/vendor-part-status/vendor-part-status.component';
import { VendorComponent } from './supplier/vendor/vendor.component';
import { PandaScreenLayoutComponent } from './supplier/panda-trial-component/panda-screen-layout/panda-screen-layout.component';
import { AdvanceShipmentNotificationComponent } from './supplier/advance-shipment-notification/advance-shipment-notification.component';
import { CommonIndexComponent } from './common-index/common-index.component';
import { AuthGuardService } from './oauth-callback/auth-guard.service';
import { SupplierAuthGuardService } from './supplier/supplier-oauth/supplier-auth-guard.service';
// import { SupplierModule } from './supplier/supplier.module';
const routes: Routes = [
	{
		path: 'oauth-callback',
		component: OAuthCallbackComponent
	},
	{
		path: 'unauthorized',
		component: ExamplePageComponent
	},
	{
		path: 'supplier-unauthorized',
		component: UnauthorizedUserComponent
	},
	{
		path: 'index',
		component: CommonIndexComponent
	},
	{
		path: 'dealer',
		loadChildren: () => import('./dealer/dealer.module').then(m => m.DealerModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'supplier',
		loadChildren: () => import('./supplier/supplier.module').then(m => m.SupplierModule),
		canActivate: [SupplierAuthGuardService]
	},
	
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule, DealerModule, SupplierModule
		// , SupplierModule
	]
})
export class AppRoutingModule { }
