<div class="asnForm">
    <form style="box-shadow: none;" #asnScreen="ngForm" novalidate>
        <small *ngIf="(enableForm)">
            <div class="content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                        <div class="p-2"
                            style="font-size: 14px; font-family:Ford Antenna;color:#00095b; font-weight: bold; margin-top:6px;">
                            Advance Shipment Notification Entry</div>
                    </div>
                </div>
            </div>
            <div>

                <div class="asn_forms asn_top">
                    <div class="form-group mx-3">
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label for="">ASN Number</label>
                                <input type="number" class="form-control" min="0" max="9999999" name="asnNumber"
                                    [(ngModel)]="asnNumber" placeholder="0" (change)="asnNumberValueCheck($event)"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="">Invoice Number(<span style="color: red;">*</span>)</label>
                                <input id="vendor" type="text" class="form-control" maxlength="15"
                                    style="text-transform:uppercase" name="invoiceNo"
                                    (change)="invoiceNumberCheck($event)" ngDefaultControl ngModel #invoiceNo="ngModel"
                                    [(ngModel)]="invoiceNumber" required
                                    oninput="this.value = this.value.replace(/[$&+,`~:;=?[\]@#|{}'<>.^*()%!-/]/,'').replace(/(\..*)\./g, '$1');" />
                                <small
                                    *ngIf="(invoiceNo.errors?.required && invoiceNo.touched) || ((!asnScreen.form.valid) && (!invoiceNo.touched && submitForm ))"
                                    class="text-danger">* Please enter the Invoice Number
                                </small>
                            </div>
                            <div class="col-md-4 mb-2">
                                <label for="">Invoice Date(<span style="color: red;">*</span>)</label>

                                <p-calendar name="invoiceDateValue" [(ngModel)]="invoiceDate" dateFormat="dd-mm-yy"
                                    (onSelect)="invoicDateCheck()" [maxDate]="maxDate" placeholder="dd-mm-yyyy"
                                    showIcon="true" inputId="endDateId" ngDefaultControl ngModel
                                    #invoiceDateValue="ngModel" required></p-calendar>
                                <small
                                    *ngIf="(invoiceDateValue.errors?.required && invoiceDateValue.touched) || ((!asnScreen.form.valid) && (!invoiceDateValue.touched && submitForm ))"
                                    class="text-danger">* Please select the invoice
                                    date</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label for="">Ship From Location Code</label>
                                <input id="vendor" type="text" class="form-control" maxlength="5" pInputText
                                    [value]="vendorCode" disabled=true readonly=true style="text-transform:uppercase" />
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="">Case Number</label>
                                <input id="vendor" type="text" class="form-control" maxlength="10"
                                    name="casenumberValue" pInputText [(ngModel)]="caseNumber"
                                    style="text-transform:uppercase" ngDefaultControl ngModel
                                    oninput="this.value = this.value.replace(/[$&+,`~:;=?[\]@#|{}'<>.^*()%!-/]/,'').replace(/(\..*)\./g, '$1');" />
                            </div>
                            <div class="col-md-4 mb-2">
								<label for="">Packing Slip Number</label>
								<input id="inputBox" type="number" class="form-control" maxlength="6" pInputText
									   [value]="slipNumber" disabled=true readonly=true  />
							</div>
						</div>
                    </div>
                </div>

                <div class="asn_forms asn_center">
                    <div class="form-group mx-3">
                        <div class="row">
                            <div class="col-md-4">
                                <label for="">Part Number(<span style="color: red;">*</span>)</label>
                                <div class="mb-3" *ngFor='let item of [].constructor(10);let i = index'>
                                    <input type="text" class="form-control" pInputText name="partNumberValue"
                                        (change)="partNumberValueCheck($event,i)" ngDefaultControl ngModel
                                        style="text-transform:uppercase"
                                        oninput="this.value = this.value.replace(/[$&+,`~:;=?[\]@#|{}'<>.^*()%!-/]/,'').replace(/(\..*)\./g, '$1');" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label for="">Quantity Shipped(<span style="color: red;">*</span>)</label>
                                <div class="mb-3" *ngFor='let item of [].constructor(10);let i = index'>
                                    <input type="number" class="form-control" min="0" pInputText name="qtySupplied"
                                        placeholder="0" (change)="quantitySuppliedValidation($event,i)" ngDefaultControl
                                        ngModel
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label for="">Cumulative Quantity Shipped</label>
                                <div class="mb-3" *ngFor='let item of [].constructor(10);let i = index'>
                                    <input type="number" class="form-control" min="0" pInputText name="cumqtySupplied"
                                        placeholder="0" (change)="cumQuantitySuppliedValidation($event,i)"
                                        ngDefaultControl ngModel
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="asn_forms asn_bottom">
                    <div class="form-group mx-3">
                        <div class="row">
                            <div class="col-md-12 my-4">
                                <div class="d-flex align-items-center justify-content-between mb-5">
                                    <div>
                                        <!-- (click)="onOkClick()" -->
                                        <button type="submit" class="btn btn_secondary mr-5"
                                            (click)="actionSubmit()">Ok</button>
                                        <button type="search" id="cancel-button-asn" class="btn btn_secondary"
                                            (click)="cancelButton()">Cancel</button>
                                    </div>
                                    <button type="search" style="margin-right: -410px;" (click)="downloadTemplate()"
                                        class="btn_template">
                                        Template
                                    </button>
                                    <button type="search" data-toggle="modal" data-target="#exampleModalCenter"
                                        class="btn_bulk">
                                        Bulk Load
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </small>
    </form>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header border-0 m-0 mr-1 p-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container" id="market-datum">
                    <div class="row justify-content-md-center">
                        <div class="col col-lg-2">

                        </div>
                        <div class="col-md-auto">
                            <h5 class="modal-title w-100 text-center mb-3" id="exampleModalLongTitle">Bulk Load</h5>
                            <form style="box-shadow: none;" #asnFileUpload="ngForm" (ngSubmit)="fileErr()"
                                class="m-3 p-3">
                                <label for="customFile" class="h5 mb-3">Document</label>
                                <input type="file" class="form-control-file" (change)="onChange(fileUpload.value)"
                                    name="fileUpload" id="customFile" ngDefaultControl ngModel #fileUpload="ngModel"
                                    accept="csv">
                                <small>Document type Comma Seperated Value File(.csv)</small>
                                <div class="m-2 p-2">
                                    <button type="submit" id="upload-button">Upload</button>
                                    <button class="ml-3" type="button" id="cancel-button"
                                        data-dismiss="modal">Close</button>
                                </div>
                            </form>
                        </div>
                        <div class="col col-lg-2">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Required for Message Service -->
<p-toast></p-toast>
