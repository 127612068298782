import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {MessageService, SelectItem} from 'primeng/api';
import { CommonService } from 'src/app/dealer/services/common.service';
import { MenuItem } from './model/menu-Item';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminStackService } from './services/admin-stack.service';
import { HomeService } from './services/home.service';
import { Market } from './model/market';
import { MarketData } from './model/marketData';
import { HomeComponent } from './home/home.component';
import { DesignService } from './services/design.service';
import { SupplierService } from '../services/supplier.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent implements OnInit {

  // Side bar Details
  sideBar = false;
  menuContents: any[];
  menuKeys: any;
  selectedMenu: number = 100;
  sidebarDesign: {selectedItem: number, isFastPath: boolean};

  // Market Data
  market: string;
  markets: Market[];
  marketItem: Market;
  gsdbCode: string;
  marketId: string;
  userName: string;
  gsdbs: string[];
  marketData: MarketData;

  // Login Data
  cdsid: { id: string, isCovisint: boolean } = {
		id: '',
		isCovisint: false
	};
  userData: { fsnId: string, name: string} = {
		name: '',
		fsnId: '',
	}
  isActiveDirectory: boolean;
  adminModelView: AdminStackService;

  today: Date;
  showDialog: boolean = false;

  constructor(private messageService: MessageService, private readonly commonService: CommonService,
    private router: Router, private activatedRoute: ActivatedRoute, private supplierService: SupplierService,
    private adminStackService: AdminStackService, private designService: DesignService, private homeService: HomeService) {
    this.menuContents = MenuItem.getMenuItems();
  }

  ngOnInit(): void {
    this.marketData = JSON.parse(sessionStorage.getItem('market-data'));
    this.cdsid = JSON.parse(sessionStorage.getItem('cdsid'));
    this.menuKeys = JSON.parse(sessionStorage.getItem('menukeys'));
    this.userData = JSON.parse(sessionStorage.getItem('userData'));
    this.isActiveDirectory = JSON.parse(sessionStorage.getItem('isActiveDirectory'));
    this.loadSideBarMenu();
    this.fastPath();
    this.today = new Date();
    if (this.cdsid != null) {
      this.homeService.getMarket(this.cdsid.id).subscribe(response => {
        this.markets = this.homeService.setMarketData(response.filter((data: string) => data.toUpperCase() !== 'SBOM'));
      })
    }
    if (this.marketData === null) {
      this.market = '';
      this.gsdbCode = '';
      this.marketId = '';
      document.getElementById('market-gdsb').click();
    } else {
      this.market = this.marketData.marketName;
      this.gsdbCode = this.marketData.gsdb;
      this.marketId = this.marketData.marketId;
    }
  }

  changeMarket(event) {
    this.marketItem = this.homeService.dataSet(event);
    this.market = this.marketItem.marketName;
	//To populate Covisint GSDB
    if(this.cdsid.isCovisint){
      this.supplierService.fetchAttributes(this.userData.fsnId, sessionStorage['gateway_access_token']).subscribe((response:any) =>{
        this.gsdbs = response.RoleAssignment[0].DataAttributes[0].Attributes[0].AttributeValues.sort();
      })
    }else{
	  this.gsdbCode = '';
      this.homeService.getGSDB(this.marketItem.marketId, this.cdsid.id).subscribe((response: string[]) => {
        this.gsdbs = response.sort();
      })
    }
	 /* this.homeService.getGSDB(this.marketItem.marketId, this.cdsid.id).subscribe((response: string[]) => {
		this.gsdbs = response.sort();
	  })*/
 }

  selectMarket() {
    this.marketData = new MarketData();
    if (this.marketItem && this.gsdbCode) {
		this.homeService.verifyVendor(this.marketItem.marketId, this.gsdbCode).subscribe(response => {
			if (response === 'Valid Vendor'){
				this.marketData.marketData(this.marketItem.marketName, this.gsdbCode, this.marketItem.marketId);
				sessionStorage.setItem('market-data', JSON.stringify(this.marketData));
				this.adminStackService.getMarketValue(this.marketData.marketName);
				document.getElementById('close-button').click();
				this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
					this.router.navigate(['/supplier/home']);
				});
			}else{
				this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Supplier does not have access to this Market', life: 5000 });
			}
		})
    } else {
		this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select the correct Delivery Location and Supplier Code', life: 5000 });
    }
  }

  loadSideBarMenu() {
    this.menuContents = this.menuContents.filter(menu => {
      if (this.menuKeys.includes(menu['key'])) {
        return menu;
      }
    });
  }

  onToggle() {
    this.sideBar = !this.sideBar;
  }

  setActiveMenuItem(index: number) {
    this.selectedMenu = index;
  }

  fastPath(){
    let fastPath: {selectedItem: number, isfastPath: boolean} = this.designService.getItemNo();
    if(fastPath.isfastPath){
      this.setActiveMenuItem(fastPath.selectedItem);
      this.designService.customreset();
    }
  }

  changeGSDB(event) {
    this.gsdbCode = event;
  }

  FDSHome(){
    sessionStorage.removeItem('market-data');
    sessionStorage.removeItem('isActiveDirectory');
    window.location.href = environment.hostUrl + 'index';
  }

  showDialogBox(){
	this.showDialog = true;
  }

  navigateToSupplier() {
	  this.showDialog = false;
	  document.getElementById('market-gdsb').click();
  }

  logout(){
    sessionStorage.clear();
    window.location.href = environment.hostUrl;
  }
  onReject(){
    
  }
}
