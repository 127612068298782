<div class="content">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12" style="text-align: center;">
            <div id="item-heading" class="h3 p-2">Vendor Contact Details Inquiry</div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Vendor Code </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail" id="vendor-code-a79" >{{vendorDetails?.vdrCode}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Name </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">{{vendorDetails?.name}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Postal Address </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">{{vendorDetails?.street}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Line 2 </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">{{vendorDetails?.town}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">State </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">{{vendorDetails?.state}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-boldt vendor-label">Country </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">{{vendorDetails?.country}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Post Code </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">{{vendorDetails?.postCode}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Vendor Email Address </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">{{vendorDetails?.emailID1}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Vendor EDI Email Address </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">{{vendorDetails?.emailID2}}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Delivery Week </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    1 <input class="delivery-box" type="checkbox" onclick=" return false;" [checked]="firstWeek" id="">
                    2 <input class="delivery-box" type="checkbox" onclick=" return false;" [checked]="secondWeek" id="">
                    3 <input class="delivery-box" type="checkbox" onclick="return false;" [checked]="thirdWeek" id="">
                    4 <input class="delivery-box" type="checkbox" onclick="return false;" [checked]="fourthWeek" id="">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Day </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">{{vendorDetails?.day}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Source Invoice Currency </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">{{vendorDetails?.incomeCurrency}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Exec Contact </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">{{vendorDetails?.contact1}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Normal Contact </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">{{vendorDetails?.contact2}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Other Contact </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">{{vendorDetails?.contact3}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Phone </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="vendor-detail">&nbsp;{{vendorDetails?.telePhone}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<br>

<div class="font-weight-bold" id="help-msg">
    *Please reach out to your Ford Contacts for updating any details
</div>

<!-- <div class="content">
    <div class="row">
        <div class="col-lg-3 col-md-2 col-sm-1">
            <div
                style="margin-left: 20px; font-weight: bold; color:#00095b; margin-top:6px; font-size: 12px; font-family: Ford Antenna;">
                Message Key</div>
            <div style="margin-left: 20px; font-weight: bold; font-family: Ford Antenna;">A79</div>
        </div>
        <div class="col-lg-6 col-md-2 col-sm-1">
            <div
                style="font-weight: bold; color:#00095b; font-family: Ford Antenna; margin-left:135px ; margin-top:12px;">
                Vendor Contact Details
            </div>
        </div>
        <div class="col-lg-3 col-md-2 col-sm-1">
            <div style="font-weight: bold; font-family: Ford Antenna; margin-top:15px;">{{todaysDataTime}}</div>
        </div>
    </div>
</div> -->

<!-- <div class="row">
    <div class="col-sm-3 text" id="nameAlign">Vendor</div>
    <div class="col-sm-3 value">
        <div class="row">
            <div class="col-sm-4">
                <input id="contentText" class="form-control form-control-sm" disabled="noneditable" type="text"
                    value="0160A" placeholder=".form-control-sm">
            </div>
        </div>
    </div>
    <div class="col-sm-2 text1" id="nameAlign">Delivery Week</div>
    <div class="col-sm-4">
        1 <input type="checkbox" name="" [(ngModel)]="firstWeek" id="" style="margin-right: 10px;">
        2 <input type="checkbox" name="" [(ngModel)]="secondWeek" id="" style="margin-right: 10px;">
        3 <input type="checkbox"  disabled="noneditable" name=""  [(ngModel)]="thirdWeek" id=""
            style="margin-right: 10px;">
        4 <input type="checkbox" disabled="noneditable" name="" [(ngModel)]="fourthWeek" id=""
            style="margin-right: 10px;">
    </div>
</div>

<div class="row">
    <div class="col-sm-3 text" id="nameAlign">Name</div>
    <div class="col-sm-5 value">
        <div class="row">
            <div class="col-sm-5">
                <input id="contentText" class="form-control form-control-sm" disabled="noneditable" type="text"
                    pInputText [(ngModel)]="vendorName" placeholder=".form-control-sm">
            </div>
        </div>
    </div>
    <div class="col-sm-2 text2" id="nameAlign">Day</div>
    <div class="col-sm-2 value">
        <input id="contentText" class="form-control form-control-sm" disabled="noneditable" type="text" InputText
            [(ngModel)]="day">
    </div>
</div>

<div class="row">
    <div class="col-sm-3 text " id="nameAlign">Postal Address</div>
    <div class="col-sm-4 value">
        <div class="row">
            <div class="col-sm-9">
                <input id="contentText" class="form-control form-control-sm" type="text" InputText
                    [(ngModel)]="postalAddress" disabled="noneditable">
            </div>
        </div>
    </div>
    <div class="col-sm-3 text2" id="nameAlign"> Source Income Currency</div>
    <div class="col-sm-2 value">
        <input id="contentText" class="form-control form-control-sm" type="text" disabled="noneditable" InputText
            [(ngModel)]="incomeCurrency">
    </div>
</div>

<div class="row">
    <div class="col-sm-3 text" id="nameAlign">Line2</div>
    <div class="col-sm-4 value">
        <div class="row">
            <div class="col-sm-9">
                <input id="contentText" class="form-control form-control-sm" disabled="noneditable" type="text"
                    InputText [(ngModel)]="town">
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm-3 text" id="nameAlign">State</div>
    <div class="col-sm-4 value">
        <div class="row">
            <div class="col-sm-9">
                <input id="contentText" class="form-control form-control-sm" disabled="noneditable" type="text"
                    InputText [(ngModel)]="state">
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm-3 text" id="nameAlign">Country</div>
    <div class="col-sm-4 value">
        <div class="row">
            <div class="col-sm-9">
                <input id="contentText" class="form-control form-control-sm" type="text" disabled="noneditable"
                    InputText [(ngModel)]="country">
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-sm-3 text" id="nameAlign">Post Code</div>
    <div class="col-sm-4 value">
        <div class="row">
            <div class="col-sm-9">
                <input id="contentText" class="form-control form-control-sm" disabled="noneditable" type="text"
                    InputText [(ngModel)]="postalCode">
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm-3 text" id="nameAlign">Email Vendor Address</div>
    <div class="col-sm-4 value">
        <div class="row">
            <div class="col-sm-9">
                <input id="contentText" class="form-control form-control-sm" disabled="noneditable" type="text"
                    InputText [(ngModel)]="emailIdVendor">
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm-3 text" id="nameAlign">Email EDI Vendor Address</div>
    <div class="col-sm-4 value">
        <div class="row">
            <div class="col-sm-9">
                <input id="contentText" class="form-control form-control-sm" disabled="noneditable" type="text"
                    InputText [(ngModel)]="emailId">
            </div>
        </div>
    </div>
</div>
<br>
<div class="row">
    <div class="col-sm-3 text" id="nameAlign">Exec Contact</div>
    <div class="col-sm-4 value">
        <div class="row">
            <div class="col-sm-12">
                <input id="contentText" class="form-control form-control-sm" disabled="noneditable" type="text"
                    InputText [(ngModel)]="conatct1">
            </div>
        </div>
    </div>
    <div class="col-sm-2 text4" id="nameAlign"> Phone</div>
    <div class="col-sm-3 value">
        <input id="contentText" class="form-control form-control-sm" disabled="noneditable" type="text" value="0">
    </div>
</div>

<div class="row">
    <div class="col-sm-3 text" id="nameAlign">Normal Contact</div>
    <div class="col-sm-4 value">
        <div class="row">
            <div class="col-sm-12">
                <input id="contentText" class="form-control form-control-sm" disabled="noneditable" type="text"
                    InputText [(ngModel)]="contact2">
            </div>
        </div>
    </div>
    <div class="col-sm-2 text4" id="nameAlign"> Phone</div>
    <div class="col-sm-3 value">
        <input id="contentText" class="form-control form-control-sm" disabled="noneditable" type="text" InputText
            [(ngModel)]="telephone">
    </div>
</div>

<div class="row">
    <div class="col-sm-3 text" id="nameAlign">Other Contact</div>
    <div class="col-sm-4 value">
        <div class="row">
            <div class="col-sm-9">
                <input id="contentText" class="form-control form-control-sm" disabled="noneditable" type="text"
                    InputText [(ngModel)]="contact3">
            </div>
        </div>
    </div>
</div>
<br>
<div class="row">
    <div class="col-sm-12" style="color: #00095b; font-weight: bolder; font-family:Ford Antenna;">*Please reach out to
        your Ford Contacts for updating any details</div>
</div>
<br> -->
<!-- <div class="row">
    <div class="col-sm-2">
        <button type="button" id="searchsButton" class="btn btn-primary">Ok</button>
    </div>
    <div class="col-sm-9">
    </div>
</div> -->
<!-- <div class="col-lg-2 col-md-2 col-sm-6">
            <div id="item-label">Msg Key</div>
            <div id="item-description">
                A79</div>
        </div> -->
        <!-- <div class="col-lg-2 col-md-2 col-sm-6">
            <div id="date-time">
                {{todaysDataTime}}</div>
        </div> -->
        <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="mobile-data">
            <div class="row">
                <div class="col-lg-2 col-md-2 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">RapiFax No &nbsp;</div>
                </div>
                <div class="col-lg-7 col-md-2 col-sm-6 col-6">
                    <div class="vendor-detail">&nbsp;{{vendorDetails?.faxNo}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-2 col-md-2 col-sm-6 col-6">
                    <div class="font-weight-bold vendor-label">Phone &nbsp;</div>
                </div>
                <div class="col-lg-7 col-md-7 col-sm-6 col-6">
                    <div class="vendor-detail">&nbsp;{{vendorDetails?.telePhone}}</div>
                </div>
            </div>
        </div> -->