import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-Dashobard',
  templateUrl: './Dashobard.component.html',
  styleUrls: ['./Dashobard.component.scss']
})
export class DashobardComponent implements OnInit {

  constructor(private router: Router) { }
  ngOnInit() {

  }
  onSubmit(){
    
  }
}
