<!-- 
    Section: Announcement Form
    Purpose: To submit form data
    Required fields: Start date, End date, market(multiple select), Announcement title and comments.
    Layout Used: Html forms.
    Styling: Bootstrap and customized
 -->
<div class="container form-text" id="top">
    <div class="text-center h2 m-2 p-2">Add Announcement</div>
    <form #adminStack="ngForm" (ngSubmit)="actionSubmit()" novalidate>
        <div class="row mt-4">
            <div class="col-lg-2">
                <div class="form-group label-text">
                    <label for="startDate">Start Date: </label>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <p-calendar name="startDate" [(ngModel)]="fromDate" dateFormat="dd-mm-yy"
                        [disabled]="startDateDisable" (onSelect)="onDateChange()" [minDate]="minDate"
                        placeholder="dd-mm-yyyy" showIcon="true" inputId="startDateId" ngDefaultControl ngModel
                        #startDate="ngModel" required></p-calendar>
                    <input type="date" name="startDate" [(ngModel)]="fromDate" ngDefaultControl ngModel hidden>
                    <small
                        *ngIf="(startDate.errors?.required && startDate.touched) || ((!adminStack.form.valid) && !startDate.touched && submitFormData)"
                        class="text-danger"> <br>* Please select the start date</small>
                </div>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-2">
                <div class="form-group label-text">
                    <label for="endDate">End Date: </label>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <p-calendar name="endDate" [(ngModel)]="toDate" dateFormat="dd-mm-yy" [disabled]="endDateDisable"
                        [minDate]="fromDate" placeholder="dd-mm-yyyy" showIcon="true" inputId="endDateId"
                        ngDefaultControl ngModel #endDate="ngModel" required></p-calendar>
                    <small
                        *ngIf="(endDate.errors?.required && endDate.touched) || ((!adminStack.form.valid) && !endDate.touched && submitFormData)"
                        class="text-danger"> <br>* Please select the end date</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2">
                <div class="form-group label-text">
                    <label for="market">Market: </label>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <p-multiSelect [options]="markets" #multiselect ngModel ngDefaultControl name="multiselect"
                        [(ngModel)]="selectedMarkets" ngDefaultControl (onChange)="changeMarket($event)"
                        defaultLabel="Select Market" optionLabel="marketName" display="chip" required (onClick)="check(multiselect)"></p-multiSelect>
                    <small
                        *ngIf="(!multiselect.filled && isMultiselectTouched && multiselect.valuesAsString==='Select Market') || ((!adminStack.form.valid) && !multiselect.filled && submitFormData)"
                        class="text-danger">* Please select the market</small>
                </div>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-2 ">
                <div class="form-group label-text">
                    <label for="market">Announcement Title: </label>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <input type="text" id="text-input" [(ngModel)]="announcementTitle" class="form-control"
                        editable="true" ngDefaultControl ngModel #announcement="ngModel" name="announcement" required />
                    <small
                        *ngIf="announcement.errors?.required && announcement.touched || ((!adminStack.form.valid) && !announcement.touched && submitFormData)"
                        class="text-danger">* Please enter the Announcement</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2">
                <div class="form-group label-text">
                    <label for="comments">Comments: </label>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="form-group">
                    <textarea rows="4" cols="60" name="comments" id="comments" class="form-control"
                        [(ngModel)]="comment" ngDefaultControl ngModel #comments="ngModel" name="comments"
                        required></textarea>
                    <small
                        *ngIf="(comments.errors?.required && comments.touched) || ((!adminStack.form.valid) && !comments.touched && submitFormData)"
                        class="text-danger">* Please enter the Comments</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 text-right">
                <div class="form-group">
                    <button type="submit" id="submit-button" class="btn btn_secondary mr-3">Submit</button>
                    <button type="button" id="submit-button" class="btn btn_secondary ml-2 mr-5"
                        (click)="clearall()">Clear</button>
                </div>
            </div>
        </div>
    </form>
</div>

<br>

<p-toast></p-toast>

<!-- 
    Section: Announcement Table
    Purpose: Displays the announcement details
    Layout Used: ng-prime table.
    Styling: mg-prime and customized
 -->
<div class="container">
    <div class="card">
        <div class="p-2 m-1" id="table-heading">
            <div class="d-flex justify-content-center">
                <div class="p-2"><span class="h5 text-center mr-2">Announcements</span>
                    <button style="font-size: 0.5rem;" pButton pRipple
                        class="p-button-rounded p-button-warning p-ripple p-button p-component p-button-icon-only">
                        <i class="pi pi-bell" style="font-size: 1.3rem;"></i></button>
                </div>
            </div>
        </div>
        <p-table [value]="adminModelList"
            styleClass="p-datatable-responsive-demo p-datatable-striped p-datatable-gridlines" [paginator]="true"
            [rows]="5" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            tableStyleClass="table-border" [rowsPerPageOptions]="[5,10,15]">
            <ng-template pTemplate="header">
                <tr>
                    <th id="header-cell-one"><span class="head-cell">Announcement Title</span></th>
                    <th id="header-cell-one"><span class="head-cell">From Date</span></th>
                    <th id="header-cell-one"><span class="head-cell">To Date</span></th>
                    <th id="header-cell-one"><span class="head-cell">Market</span></th>
                    <th id="header-cell-one"><span class="head-cell">Comments</span></th>
                    <th id="header-cell-one" style="width: 15%;"><span class="head-cell">Option</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-announcement>
                <tr>
                    <td id="comments-cell"><span class="p-column-title">Announcement Title</span><span
                            class="cell-content">{{announcement.commentTitle}}</span></td>
                    <td id="data-cell"><span class="p-column-title">From Date</span><span
                            class="cell-content">{{announcement.fromDate | date:'dd-MM-yyyy'}}</span></td>
                    <td id="data-cell"><span class="p-column-title">To Date</span><span
                            class="cell-content">{{announcement.toDate | date:'dd-MM-yyyy'}}</span></td>
                    <td id="comments-cell"><span class="p-column-title">Market</span><span
                            class="cell-content">{{announcement.market}}</span></td>
                    <td id="comments-cell"><span class="p-column-title">Comments</span><span
                            class="cell-content">{{announcement.comment}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Option</span><span class="cell-content">
                            <button pButton pRipple data-toggle="modal" data-target="#info"
                                class="p-button-rounded p-button-info p-ripple p-button p-component p-button-icon-only"
                                id="option" (click)="displayAnnouncement(announcement)"><i
                                    class="pi pi-exclamation-circle" style="font-size: 1.3rem;"></i></button>
                            <button pButton pRipple
                                class="p-button-rounded p-button-success p-ripple p-button p-component p-button-icon-only"
                                id="option" (click)="editAnnouncement(announcement)"><i class="pi pi-pencil"
                                    style="font-size: 1.3rem;"></i></button>
                            <button pButton pRipple
                                class="p-button-rounded p-button-danger p-ripple p-button p-component p-button-icon-only"
                                id="option" (click)="deleteConfirmation(announcement)"><i class="pi pi-times"
                                    style="font-size: 1.3rem;"></i></button>
                        </span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>


<!-- 
    Section: Comment/Market Details
    Purpose: Displays the Comments/markets in detail.
    Layout Used: Boostrap modal.
    Styling: Boostrap and customized
 -->
<div class="modal fade" id="info" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="infoLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="infoLabel">{{dispAnnouncement?.commentTitle}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-3 head-cell">Market: </div>
                    <div class="col-8">{{dispAnnouncement?.market}}</div>
                </div>
                <div class="row mt-2">
                    <div class="col-3 head-cell">Comments: </div>
                    <div class="col-8">{{dispAnnouncement?.comment}}</div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>

</div>


<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
