<br>
<div class="container">
    <h3 id="main-head">Announcements</h3>
    <div class="card mb-3" *ngFor="let announcement of adminModelView">
        <div class="row g-0">
            <div class="col-md-1 p-2 m-2 text-center" id="image">
                <img src="../../../assets/image/announcement.jpg" class="img-thumbnail" alt="...">
            </div>
            <div class="col-md-8">
                <div class="card-body">
                    <h5 class="card-title">{{announcement?.commentTitle}}</h5>
                    <p class="card-text mt-2 pt-2">{{announcement?.comment}}</p>
                    <p class="card-text"><small class="text-muted">{{announcement.fromDate | date:'MMM dd yyyy'}} -
                            {{announcement.toDate | date:'MMM dd yyyy'}}</small></p>
                </div>
            </div>
        </div>
    </div>
</div>
