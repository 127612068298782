import { getLocaleDateFormat } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PartOrderStatusService } from '../services/part-order-status.service';
import { PartNumberDataModel } from './model/PartNumberDataModel';
import { Ordercount, PartNumberDetails } from './model/PartNumberDetails.model';
import { formatDate } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketData } from '../model/marketData';
import { DesignService } from '../services/design.service';
import { Paginator } from 'primeng/paginator';
import { NgForm } from '@angular/forms';
import { PartComment } from '../model/PartComment';
@Component({
  selector: 'part-order-status',
  templateUrl: './part-order-status.component.html',
  styleUrls: ['./part-order-status.component.scss'],
  // providers: [MessageService]
})
export class PartOrderStatusComponent implements OnInit {

  // Part Data
  products: any[];
  totalData: any[];
  partNumber: number;
  engineeringPartNumber:string;
  name:string;
  partName: string = '';
  status: string = '';
  refNumber: number;
  showOverlaySpinner: boolean;
  showOverlay: boolean;
  partNumberDatas: PartNumberDataModel[] = null;
  partNumberDatasTemp: PartNumberDataModel[] = null;
  partNumberDetailsData: PartNumberDetails[] = [];
  partNumberDetailsResponseData: PartNumberDetails[] = [];
  baseOrderYear: number;
  baseReceiptYear: number;
  totOrder: number;
  countHolder: Ordercount = new Ordercount();
  ordercountArray: any[] = [];
  isSpinner: any = 0;
  

  // Table pagination
  first: number = 0;
  rows: number = 5;

  marketData: MarketData;
  outStandingSwitch: boolean = false;
  @ViewChild('op', { static: false }) overlayPanel;
  @ViewChild('paginatortable', { static: false }) paginatortable: Paginator;
  @ViewChild('paginatortablePartData', { static: false }) paginatortablePartData: Paginator;

  // Parts Comment
  commentDetails: PartComment[];
  refNo: string;
  vendorCode: string;
  comment: string;
  isComment: boolean;
  isAddComment: boolean;
  isCommentAdded: boolean = false;
  isCommentData: boolean = false;
  @ViewChild('partComment') partComment: NgForm;
  partOrderStatusData: PartNumberDetails;
  minDate: Date;
  estimatedDate: Date;
  isCommentMandatory: boolean;

  // Login Data
  cdsid: { id: string, isCovisint: boolean } = {
    id: '',
    isCovisint: false
  };

  constructor(private partOrderStatusService: PartOrderStatusService, private activatedRoute: ActivatedRoute,
    private router: Router, private messageService: MessageService, private designService: DesignService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.refNumber = params['refNumber'];
      this.partNumber = params['partNumber'];
      this.partName = params['partName'];
      this.status = params['status'];
      this.engineeringPartNumber=params['engineeringPartNumber'];
    });
    this.minDate = new Date();
    this.marketData = JSON.parse(sessionStorage.getItem("market-data"));
    this.cdsid = JSON.parse(sessionStorage.getItem("cdsid"));
    this.partOrderStatusService.getPartNumberDetails(this.marketData.gsdb, this.marketData.marketId).subscribe((response: any[]) => {
      if (response.length > 0) {
        this.showOverlaySpinner = true;
        this.showOverlay = false;
        this.partNumberDatas = response;
        this.partNumberDatasTemp = response;
        this.showOverlaySpinner = false;
        this.showOverlay = true;
        this.isSpinner = 1; // To stop spinner
      }else{
        this.isSpinner = 1;
      }
    })
    this.products = [
      { field: "Order", header: "" },
      { field: "Receipt", header: "" },
    ];
    this.totalData = [
      { field: "On Order", header: "" },
      { field: "In Transit", header: "" },
    ];
    if (this.refNumber && this.partName && this.partNumber && this.status) {
      this.searchClick();
    }
  }

  checkPart(){
    // To ensure spinner stops and message is displayed
    if(this.partNumberDatas==null && this.isSpinner!=0){
      this.isSpinner = 1;
      this.overlayPanel.hide();
      this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Part Details Unavailable for Supplier Code: ' + this.marketData.gsdb, id: 'pop-up-message', life: 8000 });
    }
  }
  onShow()
  {
     
    this.partNumberDatas=this.partNumberDatasTemp;
    }
  // Search Fucntionality for PartNumber
  checkKey(str: string){
    
    if(str){
      this.partNumberDatas = this.partNumberDatasTemp;
      this.partNumberDatas = this.partNumberDatas.filter(data => {
        return data.gpfNpno.replace(/[\s]+/g,'').toLowerCase().includes(str.replace(/[\s]+/g,'').toLowerCase()) || data.hvyEpNo.replace(/[\s]+/g,'').toLowerCase().includes(str.replace(/[\s]+/g,'').toLowerCase());
      })
    }
    
    else{
      this.partNumberDatas = this.partNumberDatasTemp;
    }
    this.paginatortablePartData.first = 0;
  }

  // Selecting PartNumber from Overlay panel
  onRowSelect(event) {
    this.partNumber = event.data.gpfNpno.trim();
    this.partName = event.data.descNounAndSfx;
    this.status = event.data.invStatusValues;
    this.refNumber = event.data.gpfRefNo;
    this.engineeringPartNumber=event.data.hvyEpNo;
     this.partNumberDatas = this.partNumberDatasTemp;
    
    this.searchClick();
    this.overlayPanel.hide();
  }

  // Method to populate Parts Data
  searchClick() {
    this.partOrderStatusService.getDetailsPartData(this.refNumber, this.marketData.marketId, this.marketData.gsdb).subscribe(response => {
      if (response['vohTableList'].length > 0) {
        response['vohTableList'].sort((a, b) => +new Date(b.delvDate) - +new Date(a.delvDate));
        this.partNumberDetailsData = response['vohTableList'];
        this.partNumberDetailsResponseData = response['vohTableList'];
        this.baseOrderYear = response['orderBaseYear'];
        this.baseReceiptYear = response['reciptBaseYear'];
        this.totOrder = response['totOnOrder'];
        var countHolder: Ordercount = new Ordercount();
        countHolder.orderCount = this.baseOrderYear;
        countHolder.receiptCount = this.baseReceiptYear;
        this.ordercountArray.push(countHolder);
        this.products[0].header = this.baseOrderYear;
        this.products[1].header = this.baseReceiptYear;
        this.totalData[0].header = this.totOrder;
        this.totalData[1].header = response['intransit']
        this.outStandingSwitch = false;
        this.paginatortable.first = 0;
      } else {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: 'No Sightings data present', id: 'pop-up-message', life: 5000 });
      }
    })
  }

  // Table Pagination methods
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.partNumberDetailsData ? this.first === (this.partNumberDetailsData.length - this.rows) : true;
  }

  isFirstPage(): boolean {
    return this.partNumberDetailsData ? this.first === 0 : true;
  }

  // Fast Path
  showSuccess() {
    if (this.refNumber) {
      this.designService.setFastPath(4);
      this.router.navigateByUrl('/panda-trial-component', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/supplier/panda-trial-component'],
          { queryParams: { refNumber: this.refNumber, partNumber: this.partNumber,engineeringPartNumber:this.engineeringPartNumber, status: this.status, partName: this.partName } });
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Kindly select the Part Number', id: 'pop-up-message', life: 5000 });
    }
  }

  redirectToHome() {
    this.designService.setFastPath(0);
    this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/supplier/home']);
    });
  }

  onOutStandingSwitchChange(event) {
    let isChecked: boolean = event.checked;
    this.partNumberDetailsData = isChecked ? this.partNumberDetailsData.filter((data) => data.osQtyValue) : this.partNumberDetailsResponseData;
    this.paginatortable.first = 0;
  }

  // Part Data comment Section
  displayComments(partOrderStatus: PartNumberDetails) {
    this.commentDetails = null;
    this.partOrderStatusData = partOrderStatus;
    this.vendorCode = this.marketData.gsdb;
    this.partOrderStatusService.getP20Comments(partOrderStatus.refNo, this.marketData.gsdb, partOrderStatus.delvDate, this.marketData.marketId).subscribe((response: PartComment[]) => {
      if (response && response.length > 0) {
        this.isComment = false;
        response.sort((a, b) => +new Date(b.supCreatedTimestamp) - +new Date(a.supCreatedTimestamp));
        response.map(data => {
          let newTimezone = new Date(data.supCreatedTimestamp);
          newTimezone.setMinutes(newTimezone.getMinutes() - newTimezone.getTimezoneOffset());
          data.supCreatedTimestamp = newTimezone;
        });
        this.commentDetails = response;
      } else {
        this.isComment = true;
      }
    })
  }

  submitComment() {
    let p20comment: PartComment = {};
    p20comment.supComments = this.comment ? this.comment.trim() : null;
    p20comment.supEtdYmd = this.estimatedDate ? formatDate(this.estimatedDate, 'yyyy-MM-dd', 'en-US') : null;
    if (p20comment.supEtdYmd || (p20comment.supComments !== '' && p20comment.supComments)) {
      this.isCommentData = false;
      p20comment.supUserType = this.cdsid.isCovisint ? "Supplier" : "Ford";
      p20comment.supCreatedBy = this.cdsid.id;
      p20comment.supCreatedTimestamp = new Date();
      p20comment.supVdrCd = this.marketData.gsdb;
      p20comment.supRefNo = Number(this.partOrderStatusData.refNo);
      p20comment.supLastUpdatedTimestamp = new Date();
      p20comment.supScreenFlg = "P20";
      p20comment.supDelvYmd = formatDate(this.partOrderStatusData.delvDate, 'yyyy-MM-dd', 'en-US');
      p20comment.supExworksDate = this.partOrderStatusData.exwYmd ? formatDate(this.partOrderStatusData.exwYmd, 'yyyy-MM-dd', 'en-US') : null;
      this.partOrderStatusService.createP20Comments(p20comment, this.marketData.marketId).subscribe(response => {
        if (response) {
          this.isCommentAdded = true;
          this.partOrderStatusService.getP20Comments(this.partOrderStatusData.refNo, this.marketData.gsdb, this.partOrderStatusData.delvDate, this.marketData.marketId).subscribe((response: PartComment[]) => {
            if (response && response.length > 0) {
              this.isComment = false;
              response.sort((a, b) => +new Date(b.supCreatedTimestamp) - +new Date(a.supCreatedTimestamp));
              response.map(data => {
                let newTimezone = new Date(data.supCreatedTimestamp);
                newTimezone.setMinutes(newTimezone.getMinutes() - newTimezone.getTimezoneOffset());
                data.supCreatedTimestamp = newTimezone;
              });
              this.commentDetails = response;
              this.searchClick();
            } else {
              this.isComment = true;
            }
          })
          this.comment = null;
          this.partComment.reset();
          setTimeout(() => {
            this.isCommentAdded = false;
            this.closeComment();
            this.closeModal();
          }, 2000);
        }
      })
    }
    else {
      this.isCommentData = true;
      setTimeout(() => {
        this.isCommentData = false;
      }, 5000);
    }
  }

  activateComment() {
    this.isAddComment = true;
  }

  closeComment() {
    this.partComment.reset();
    this.isCommentData = false;
    this.isAddComment = false;
  }

  // Method to close the comments modal
  closeModal() {
    if (this.partComment) {
      this.closeComment();
    }
    document.getElementById("close-comment-modal").click();
  }

  onReject(){
    
  }
}

