export class CsvDataService {
	static exportToCsv(filename: string, rows: object[]) {
		if (!rows || !rows.length) {
			return;
		}
		const separator = ',';
		const keys = Object.keys(rows[0]);
		const length = keys.toString().length;
		// Backend-HEADERS--
		// partNumber,partDesc,bhndSchd,boLowIndex,currMonth,nextMonth,rcptDate,rcptQty,packSlip,inProcess,gpfRefNumber,epNo
		const headers: string = 'Service Part Number,Engineering Part Number,Part Description,Behind Schedule,Behind Schedule Index,Current Month,Next 6 Months,Last Receipt Date,Last Receipt Quantity,Last Receipt Packing Slip,In Transit';
		let csvContent =
			keys.join(separator) +
			'\n' +
			rows.map(row => {
				return keys.map(k => {
					let cell = row[k] === null || row[k] === undefined ? '' : row[k];
					cell = cell instanceof Date
						? cell.toLocaleString()
						: cell.toString().replace(/"/g, '""');
					if (cell.search(/("|,|\n)/g) >= 0) {
						cell = `"${cell}"`;
					}
					return cell;
				}).join(separator);
			}).join('\n');
		csvContent = csvContent.substr(length, csvContent.length);
		csvContent = headers.concat(csvContent);
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		if ((navigator as any).msSaveBlob) { // IE 10+
			(navigator as any).msSaveBlob(blob, filename);
		} else {
			const link = document.createElement('a');
			if (link.download !== undefined) {
				// Browsers that support HTML5 download attribute
				const url = URL.createObjectURL(blob);
				link.setAttribute('href', url);
				link.setAttribute('download', filename);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}
}
