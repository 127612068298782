import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { VendorDetailsService } from '../services/vendor-details.service';
import { VendorDetailsModel } from './model/VendorDetailsModel';
import { MarketData } from '../model/marketData';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit {

  // Login Data
  marketData: MarketData;

  // Vendor Details Data
  vendorDetails: VendorDetailsModel;
  firstWeek: boolean = false;
  secondWeek: boolean = false;
  thirdWeek: boolean = false;
  fourthWeek: boolean = false;
  categories: any[] = [{ name: '1', key: 'A' }, { name: '2', key: 'M' }, { name: '3', key: 'P' }, { name: '4', key: 'R' }];

  constructor(private vendorDetailsService: VendorDetailsService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.marketData = JSON.parse(sessionStorage.getItem("market-data"));
    this.getVendorDetails();
  }

  getVendorDetails() {
    this.vendorDetailsService.getVendorDetails(this.marketData.gsdb, this.marketData.marketId).subscribe(response => {
      if (response) {
        this.vendorDetails = response;
        if (response.deliveryWeek == 1) {
          this.firstWeek = true;
        }
        else if (response.deliveryWeek == 2) {
          this.secondWeek = true;
        }
        else if (response.deliveryWeek == 3) {
          this.thirdWeek = true;
        }
        else if (response.deliveryWeek == 4) {
          this.fourthWeek = true;
        }
        else if (response.deliveryWeek == 5) {
          this.firstWeek = true;
          this.thirdWeek = true;
        }
        else if (response.deliveryWeek == 6) {
          this.secondWeek = true;
          this.fourthWeek = true;
        }
        else if (response.deliveryWeek == 9) {
          this.firstWeek = true;
          this.secondWeek = true;
          this.thirdWeek = true;
          this.fourthWeek = true;
        }
      }else{
        this.messageService.add({ severity: 'warn', summary: 'Info', detail: 'Vendor Details unavailable', id: 'pop-up-message', life: 8000 });
      }
    });
  }

}
