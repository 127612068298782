<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">


	<mat-toolbar color="primary" class="example-toolbar   ">
		<button mat-icon-button (click)="snav.toggle()">
			<mat-icon>menu</mat-icon>
		</button>
		<a routerLink="/dashboard"><img src="/assets/image/logo5.jpg" alt="Ford" class="logo" width="100px"
				height="45px" style="margin-right: 3px; margin-top: 2px;"></a>
		<span class="line"></span>
		<h1 class="projectTitle">PANDA</h1>

		<span class="example-spacer"></span>
		<span class="username">{{userId}}</span>
		<span style="height:23px ;cursor: pointer;" (click)="showProfile($event)">
			<mat-icon>account_circle</mat-icon>
		</span>
		<div *ngIf="isProfileClicked" class="userLogout" (click)="stopPropogation($event)">
			<div class="welcomeText">Welcome {{userId}}! </div>
			<!-- <div class="welcomeText" *ngIf="!multiDealerEnable">Dealer : {{dealerId}}</div>
			<div class="welcomeText" *ngIf="multiDealerEnable" style="cursor: pointer;" (click)="swapDealer()">Dealer :
				<u>{{dealerId}} </u></div> -->
			<div class="p-d-flex ml-2" style="color:#102b4e ; " (click)="logout()">
				<div class="p-mr-2 mt-2" style="cursor: pointer;">
					<mat-icon>logout</mat-icon>
				</div>
				<div class="p-mr-2 mt-1 " style="cursor: pointer;font-size: 15px;"><span>Logout</span></div>

			</div>

		</div>

	</mat-toolbar>

	<mat-sidenav-container class="example-sidenav-container ">
		<mat-sidenav #snav [opened]="mobileQuery.matches ? 'false' : 'true'" style=" width: 17rem;"
			[mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
			class="sidenav  mat-elevation-z4">


			<!-- <mat-nav-list *ngFor="let navItem of permittedNavMenuItems" class="sidenav-list ">
				<a mat-list-item [routerLink]="navItem.routerLink"  style="margin-top: 10px;">
					<mat-icon style="color: white;">{{navItem.icon}}</mat-icon>
					<span style="font-size: 14px;">{{navItem.label}}</span>
				</a>
			</mat-nav-list> -->

			<mat-nav-list class="sidenav-list" *ngFor="let item of allNavMenuItems">
				<mat-accordion *ngIf="item.children && item.children.length > 0" >
					<mat-expansion-panel style="background-color: transparent !important;
				  box-shadow: none;" [expanded]="true" (opened)="panelOpenState = true" >
						<mat-expansion-panel-header>
							<mat-panel-title>
								<mat-icon style="color: white;">{{item.icon}}</mat-icon>
								<div style="margin-left: 0px;color: white; 
								width: 225px;">{{item.label}}</div>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-list-item style="font-family: FordAntenna;font-size: 14px;" *ngFor="let child of item.children" [routerLinkActive]="'is-active'"
							[routerLink]="child.routerLink">
							<!-- <mat-icon style="color: white;">{{child.icon}}</mat-icon> -->
							<div style="margin-left: 0px;color: white; 
							width: 225px;">{{child.label}}</div>
						</mat-list-item>
					</mat-expansion-panel>
				</mat-accordion>
				<mat-list-item style="font-family: FordAntenna;font-size: 11px;" *ngIf="!item.children || item.children.length === 0" [routerLinkActive]="'is-active'"
					[routerLink]="item.routerLink">
					<!-- Entradas principales -->
					<mat-icon style="color: white;">{{item.icon}}</mat-icon>
					<div style="margin-left: 8px;color: white;">{{item.label}}</div>
				</mat-list-item>
			</mat-nav-list>
		</mat-sidenav>

		<mat-sidenav-content>

			<section class="content">
				<router-outlet></router-outlet>
			</section>
			<div class="mainfooter">

				<h5>Copyright &copy; 2024 <a href="#">Ford Motor Company</a><span class="footer-line"></span>All rights
					reserved.</h5>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>