import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../supplier/services/home.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/dealer/services/common.service';

@Component({
	selector: 'common-index',
	templateUrl: './common-index.component.html',
	styleUrls: ['./common-index.component.scss']
})
export class CommonIndexComponent implements OnInit {

	cdsid: { id: string, isCovisint: boolean } = {
		id: '',
		isCovisint: false
	};
	userData: { fsnId: string, name: string } = {
		name: '',
		fsnId: '',
	}
	menuKeys: any;

	constructor(private homeService: HomeService, private route: Router, private commonService: CommonService) { }

	ngOnInit(): void {
		this.cdsid = JSON.parse(sessionStorage.getItem("cdsid"));
		//To avoid unauthorized access
		if(this.cdsid == null || this.cdsid.id == null){
			window.location.href=environment.hostUrl;
		} else {
		this.userData = JSON.parse(sessionStorage.getItem("userData"));
		this.menuKeys = JSON.parse(sessionStorage.getItem("menukeys"));
		sessionStorage.setItem('commonIndex', JSON.stringify(true));
		}
	}

	navigateToDealer() {
		console.log("Dealer login")
		sessionStorage.setItem('isActiveDirectory', JSON.stringify(true));
		sessionStorage['dealerId']=this.cdsid.id;
		this.commonService.fetchRole(this.cdsid.id).subscribe(res => {
			console.log('*******Fetch Role Access ********');
			this.responseHandler(res);
		});
	}

	navigateToSupplier() {
		if (this.menuKeys) {
			sessionStorage.setItem('isActiveDirectory', JSON.stringify(true));
			sessionStorage.setItem('SupplierLogin',JSON.stringify(true));
			this.route.navigate(['/supplier']);
		}else{
			this.route.navigate(['/supplier-unauthorized']);
		}
	}

	responseHandler(response) {
		const roleAccess = ['PANDA:PartSearch', 'PANDA:PartInquiry:WithoutPriceData', 'PANDA:PartInquiry:WithPriceData'];
		if (response !== null && response !== undefined && response.Permitted !== null && response.Permitted !== undefined) {
			response.Permitted.forEach(element => {
				console.log('**** Role Access ****')
				console.log(element);
				const authorizedUrl = element.read.some((v) => {
					return roleAccess.indexOf(v) >= 0;
				});
				if (authorizedUrl) {
					const redirectURL = '/dealer';
					window.location.href = environment.hostUrl + redirectURL;
				} else {
					const redirectURL = '/unauthorized';
					window.location.href = environment.hostUrl + redirectURL;
				}
			});
		} else {
			const redirectURL = '/unauthorized';
			window.location.href = environment.hostUrl + redirectURL;
		}
	}

	logout() {
		console.log('*** Logout ***');
		localStorage.clear();
		sessionStorage.clear();
		document.location.href =
			'https://wwwqa.faust.idp.ford.com/logout?back=' + environment.hostUrl;
	}
}
