import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'unauthorized-user',
  templateUrl: './unauthorized-user.component.html',
  styleUrls: ['./unauthorized-user.component.scss']
})
export class UnauthorizedUserComponent implements OnInit {

  cdsid: string;

  constructor() { }

  ngOnInit(): void {
    //this.cdsid = sessionStorage.getItem('cdsid');
    //sessionStorage.removeItem('cdsid');
    //To avoid redirect to Dealer Portal
    sessionStorage.setItem('SupplierLogin', JSON.stringify(false));
  }

  redirect(){
    //sessionStorage.removeItem('cdsid');
    sessionStorage.setItem('SupplierLogin', JSON.stringify(false));
  }

}
