<!-- Part Data Selection -->
<div class="container-fluid">
    <button type="search" (click)="op.toggle($event); checkPart();" class="btn btn_secondary m-2 p-2" id="part-search-button-M15"><i
            class="pi pi-window-maximize"></i><span class="mr-1"> &nbsp;Search Part No.</span></button>
</div>

<!-- Overlay panel for selecting Part Number -->
<div class="containespanelr">
    <div class="row">
        <p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true" (onShow)="onShow()">
            <div class="progress-spinner" *ngIf="isSpinner==0">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
            </div>
            <ng-template pTemplate>
                <p-table #dt1 [value]="partNumberDatas" #paginatortablePartData
                    styleClass="p-datatable-responsive-demo p-datatable-striped p-datatable-gridlines"
                    selectionMode="single" [(selection)]="selectedProduct" (onRowSelect)="onRowSelect($event)"
                    [paginator]="true" [rows]="5" responsiveLayout="scroll" [globalFilterFields]="['gpfNpno']">
                    <ng-template pTemplate="caption" *ngIf="partNumberDatas">
                        <div class="p-d-flex">
                            <span class="p-input-icon-right p-ml-auto">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                (input)="checkKey($event.target.value)"  placeholder="" />
                                <!--  (input)="dt1.filterGlobal($event.target.value, 'contains')" -->
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th id="head-cell" pSortableColumn="partNumber" style="width: 27%; text-align: center;"><span class="table-head">Service Part Number</span>
                                <p-sortIcon field="partNumber"></p-sortIcon>
                            </th>
                            <th id="head-cell" pSortableColumn="partNumber" style="width: 27%;text-align: center;"><span class="table-head">Engineering Part Number</span>
                                <!-- <p-sortIcon field="partNumber"></p-sortIcon> -->
                            </th>
                            <th id="head-cell" pSortableColumn="description" style="width: 30%;text-align: center;"><span class="table-head">Part
                                Description</span></th>
                            <th id="head-cell" pSortableColumn="invStatus" style="width: 16%;text-align: center;"><span class="table-head"  >Inv Status</span>
                                <p-sortIcon field="invStatus"></p-sortIcon>
                            </th>

                            <th style="display: none;text-align: center;" pSortableColumn="refNo">ReferenceNo <p-sortIcon field="refNo">
                                </p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-product>
                        <tr [pSelectableRow]="rowData">
                            <td id="pop-up-col"><span class="p-column-title">PartNumber</span><span
                                    class="cell-content">{{product.gpfNpno}}</span></td>
                                    <td id="pop-up-col"><span class="p-column-title">Engineering Part Number</span><span
                                        class="cell-content">{{product.hvyEpNo}}</span></td>
                                        <td id="pop-up-col"><span class="p-column-title">Part Description</span><span
                                            class="cell-content">{{product.descNounAndSfx}}</span></td>
                            <td id="pop-up-col"><span class="p-column-title">InvStatus</span><span
                                    class="cell-content">{{product.invStatusValues}}</span></td>

                            <td id="pop-up-col" style="display: none">{{product.gpfRefNo}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-template>
        </p-overlayPanel>
    </div>
</div>

<!-- Part data display -->
<div class="content">
    <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-6 col-6">
            <div class="item-label">
                Service Part Number</div>
            <div class="item-description">
                <pre>{{partNumber}}</pre>
            </div>
        </div>
        <div class="col-lg-4 col-md-3 col-sm-6 col-6">
            <div class="item-label">Engineering Part Number</div>
            <div class="item-description">
                <div>{{engineeringPartNumber}}</div>
            </div>
        </div>
        <div class="col-lg-4 col-md-3 col-sm-6 col-6">
            <div class="item-label">Part Description</div>
            <div class="item-description">
                <div>{{partName}}</div>
            </div>
        </div>

    </div>
</div>

<!-- Orders and Activities Data -->
<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 col-12">
            <div style="border: 1px solid #00095b;">
                <p-table [value]="orders" styleClass="p-datatable-gridlines">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <ng-template pTemplate="body" let-data>
                        <tr>
                            <td id="head-cell">
                                {{data.part_number}}
                            </td>
                            <td id="data-cell" style="text-align: right; width: 80px;">
                                {{data.description}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12 col-12" id="activities">
            <div style="border: 1px solid #00095b;">
                <p-table [value]="onActivities" styleClass="p-datatable-gridlines">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <ng-template pTemplate="body" let-data>
                        <tr>
                            <td id="head-cell">
                                {{data.part_number}}
                            </td>
                            <td id="data-cell" style="text-align: right;">
                                {{data.description}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<!-- In Transit Table Data -->
<div class="container-fluid mt-3">
    <div class="card">
        <div class="m-1 p-2 text-center" id="table-heading-M15">In Transit Inquiry</div>
        <p-table id="p20Table" [value]="itemDetails" #paginatortable
            styleClass="p-datatable-responsive-demo p-datatable-striped p-datatable-gridlines" [paginator]="true"
            [rows]="5" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            tableStyleClass="table-border" [rowsPerPageOptions]="[5,10,15]">
            <ng-template pTemplate="header">
                <tr>
                    <th id="m15-tbl-cell-heading" style="width: 9%;">Ship Number</th>
                    <th id="m15-tbl-cell-heading" style=" width: 9%;">Ship Type</th>
                    <th id="m15-tbl-cell-heading" style=" width: 13%;">Docking Date</th>
                    <th id="m15-tbl-cell-heading" style=" width: 13.6%; ">Stock into PDC</th>
                    <th id="m15-tbl-cell-heading" style=" width: 15%;">Case Number</th>
                    <th id="m15-tbl-cell-heading" style=" width: 12.5%;">Register Number</th>
                    <th id="m15-tbl-cell-heading" style=" width: 6.3%;">Item</th>
                    <th id="m15-tbl-cell-heading" style=" width: 10.5%;">Order Number</th>
                    <th id="m15-tbl-cell-heading" style=" width: 8%;">Exp Qty</th>
                    <th id="m15-tbl-cell-heading" style=" width: 9%;">Rcvd Qty</th>
                    <th id="m15-tbl-cell-heading" style="width: 13%;">Rcvd Date</th>
                    <th id="m15-tbl-cell-heading" style="width: 10.7%;">Invoice Number</th>
                    <th id="m15-tbl-cell-heading" style="width: 11.3%;">Routing Tag No
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td id="data-cell"><span class="p-column-title">Ship Number</span><span class="cell-content"
                            style="float: right;">{{product.shipNo}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Ship Type</span><span
                            class="cell-content">{{product.shipTypeValue}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Docking Date</span><span
                            class="cell-content">{{product.dockingDateString}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Stock into PDC</span><span
                            class="cell-content">{{product.stockDateString}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Case Number</span><span class="cell-content"
                            style="float: right;">{{product.caseNoValue}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Register Number</span><span class="cell-content"
                            style="float: right;">{{product.regNo}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Item</span><span class="cell-content"
                            style="float: right;">{{product.itemNo}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Order Number</span><span
                            class="cell-content">{{product.orderNo}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Exp Qty</span><span class="cell-content"
                            style="float: right;">{{product.pctQty}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Rcvd Qty</span><span class="cell-content"
                            style="float: right;">{{product.ptQty}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Rcvd Date</span><span
                            class="cell-content">{{product.rctYmdValue}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Invoice Number</span><span class="cell-content"
                            style="float: right;">{{product.slipNumber}}</span></td>
                    <td id="data-cell"><span class="p-column-title">Routing Tag No</span><span class="cell-content"
                            style="float: right;">{{product.tagNo}}</span></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<!-- Fast Path -->
<div class="container">
    <div class="row">
        <div class="col-lg-2 col-md-6 col-sm-6 col-6">
            <button type="search" (click)="showSuccess()" class="btn btn_secondary m-2 p-2" id="fastpath-button"><i
                    class="pi pi-external-link"></i><span class="mr-1"> &nbsp;Schedule Details</span></button>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 col-6">
            <button class="btn btn_secondary m-2 p-2" id="cancel-button-m15" (click)="redirectToHome()"
                type="button">Cancel</button>
        </div>
    </div>
</div>

<!-- Required for Message Service -->
<p-toast></p-toast>
